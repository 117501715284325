import React, { Component, Fragment } from 'react';
import debounce from 'lodash.debounce';

import { fetchLocations } from '../../utils/api';
import { SvgIco } from '../SharedComponents/Icons';
import ResultsContainer from './ResultsContainer';

import './index.sass';


class LocationSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      direction: null,
      showResults: true,
      results: [],
      showNoResultsError: false,
      query: '',
    };
    this.linkElement = null;

    this.onChangeDebounced = debounce(this.onChangeDebounced, 300)
  }

  componentDidMount() {
    window.addEventListener('click', this.handleClickOutside);
  }

  componentDidUpdate(prevProps, prevState) {
    const { query } = this.state;

    this.props.selectedTypes !== prevProps.selectedTypes && query && this.fetchItems(query);
  }


  componentWillUnmount() {
    window.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside = (e) => {
    this.setState({ showResults: e.target === this.searchWrapper || this.searchWrapper.contains(e.target) });
  };

  handleEnter = () => {
    if (this.linkElement && this.props.results && this.props.results.length !== 0) {
      this.clearInput();
      window.open(this.linkElement, "_self")
    }
  };

  selectedLink = el => this.selectedElem = el;

  setRef = ref => {
    this.linkElement = ref;
    this.selectedLink(this.linkElement);
  };

  handleKeyDown = e => {
    switch (e.which) {
      case 38:
        this.setState({ direction: 'up' });
        break;
      case 40:
        this.setState({ direction: 'down' });
        break;
      case 27:
        this.setState({ direction: 0 });
        this.clearInput();
        break;
      case 13:
        this.handleEnter();
        break;
      default:
        this.setState({ direction: null });
        break;
    }
  };

  handleInputChange = event => {
    const query = event.target.value;
    this.setState({ query });

    if (/\S/.test(event.target.value)) {
      this.onChangeDebounced();
    } else {
      this.setState({results: [], showNoResultsError: false})
    }
  };

  clearInput = () => this.setState({ query: '', results: [] });

  search_id = new Date().getTime() % 2147483647;

  mapTypes = typesObj => typesObj.map(typeChk => typeChk.id);

  onChangeDebounced = () => {
    if (/\S/.test(this.state.query)) {
      this.fetchItems(this.state.query);
    } else {
      this.setState({results: []})
    }
  };

  fetchItems = query => {
    const { apiAutocompletePath } = this.props;
    const kinds = this.mapTypes(this.props.selectedTypes);
    const tabletOrMobile = window.innerWidth <= 960;
    const params = {
      query,
      kinds,
      search_id: this.search_id,
      sections: [window.env.section],
      max_entries: tabletOrMobile ? 8 : 12
    };

    fetchLocations(apiAutocompletePath, params).then(data => {
      const results = data.data;
      if (/\S/.test(this.state.query)) {
        this.setState({ results, showNoResultsError: !results.length });
      }
    });
  };

  render() {
    const { placeholder, showFixedSearchIcon, siteKey } = this.props;
    const { query, results, showNoResultsError } = this.state;
    const isInputFilled = !!query.length || this.state.translations;
    const showResultsContainer =  this.state.showResults;

    return (
      <Fragment>
        <div className="search-wrapper main-search-wrapper" ref={el => this.searchWrapper = el}>

          <input
            className={`location-search-input on-list-view ${isInputFilled ? ' filled' : ''}`}
            id="transcript"
            type="text"
            value={query}
            onChange={this.handleInputChange}
            placeholder={placeholder || I18n.t('apps.lb_showroom.goto.search_placeholder')}
            onKeyDown={this.handleKeyDown}
            maxLength="256"
          />

          { (showFixedSearchIcon || !isInputFilled) &&
            <div className="search-btn">
              <SvgIco name="search" size={16} />
            </div>
          }

          { isInputFilled && (
            <div className="location-search-clear-btn" onClick={this.clearInput}>
              <SvgIco name="close" size={16} />
            </div>
          )}

          { isInputFilled && results.length && showResultsContainer ? (
            <ResultsContainer
              results={results}
              moveDirection={this.state.direction}
              refElem={this.setRef}
            />
          ) : null}
        </div>

        { isInputFilled && showNoResultsError && results ? (
          <div className="error-wrapper">
            <div className="error-text">
              { I18n.t('apps.lb_showroom.goto.no_results') }&nbsp;
              { siteKey !== 'global' && (
                <a href="https://www.matchoffice.com">
                  { I18n.t('apps.lb_showroom.goto.link_to_global_site') }
                </a>
              )}
            </div>
          </div>
        ) : null }
      </Fragment>
    );
  }
}


export default React.memo(LocationSearch);
