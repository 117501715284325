import React, { Fragment, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { apiProvider } from "../../../api/core";
import { requestBuilder } from "../../../api/httpService";
import { SvgIco } from '../../SharedComponents/Icons';
import { Context } from "../index";

import './index.sass';


const BottomSeo = () => {

  const {
    headerData,
    initialKinds,
    keywords,
    requestParams,
    setHeaderData,
    showSeo,
    siteKey,
  } = useContext(Context);

  const { descriptionWithAssets, extendedDescription, rating, streetName, seoStatistics } = headerData;
  const { avgScore, rateableId, ratingCreatePath, showRating, votesCount } = rating;
  const { geoName } = keywords;

  const [hoveredRatingStar, setHoveredRatingStar] = useState(null);

  const currentScore = +avgScore;
  const locationsKind = initialKinds && initialKinds.length === 1 ? '.' + initialKinds[0] : '';
  const count = requestParams.locationsCount === 1 ? 'one' : 'other';

  // {locationsKind === '.parking'} Related to Danish translation aspects
  const kind = I18n.t(`global.models.location.kinds${locationsKind}${locationsKind === '.parking' ? '.seo_statistics' : '.normal'}`);
  const translatedKinds = kind[count] ? kind[count] : kind;
  const ratingHeadline = I18n.t('generic.rating_text', {
    selected_kinds: translatedKinds,
    selected_area: geoName,
  });

  // isCoworkingDk - related to Danish translation aspects
  const isCoworkingDk = siteKey === 'dk' && locationsKind === '.coworking' && true;
  const declinedLocationKinds =
    isCoworkingDk
      ? I18n.t(`global.models.location.kinds${locationsKind}.seo_statistics`)
      : translatedKinds;

  const availableLocationsLinkI18n = I18n.t(
    'apps.lb_showroom.bottom_seo.overview_link',
    {
      pdf_link: seoStatistics && seoStatistics.fullOverviewLink,
      location_kinds: declinedLocationKinds,
      location: geoName,
    },
  );

  const topVievedLocationsHeadline = I18n.t(
    'apps.lb_showroom.bottom_seo.table_top_locations_headline',
    {
      locations_count: seoStatistics && seoStatistics.topViewed.length,
      location_kinds: declinedLocationKinds,
      location: geoName,
    },
  ).replace(/\u00A0/g, ' ');


  useEffect(() => {
    initYouTubeVideos();
  }, []);


  const updateAreaRating = (score) => {
    const requestParams = {
      kind: 'list_view',
      load_stars_widget: 'false',
      id: rateableId,
      score,
    };

    apiProvider
      .post(ratingCreatePath, requestBuilder(requestParams))
      .then(response => response &&
        setHeaderData(prevData => ({
          ...prevData,
          rating: {
            ...prevData.rating,
            avgScore: response.rating_data.avg_score,
            votesCount: response.rating_data.votes_count,
          }
        }))
      );
  };


  const handleRatingHover = (e) =>
    setHoveredRatingStar(e.target.id);


  const handleRatingOut = () =>
    setHoveredRatingStar(null);


  const initYouTubeVideos = () => {
    const labnolIframe = (div) => {
      const iframe = document.createElement('iframe');
      iframe.setAttribute('src', 'https://www.youtube.com/embed/' + div.dataset.id + '?autoplay=1&rel=0');
      iframe.setAttribute('frameborder', '0');
      iframe.setAttribute('allowfullscreen', '1');
      iframe.setAttribute('autoplay', '1');
      iframe.setAttribute('allow', 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture');
      div.parentNode.replaceChild(iframe, div);
    };

    const playerElements = document.getElementsByClassName('youtube-player');

    if (playerElements && playerElements.length) {
      for (let n = 0; n < playerElements.length; n++) {
        const videoId = playerElements[n].dataset.id;
        const div = document.createElement('div');

        div.setAttribute('data-id', videoId);

        const thumbNode = document.createElement('img');

        thumbNode.src = 'https://i.ytimg.com/vi/ID/hqdefault.jpg'.replace('ID', videoId);
        div.appendChild(thumbNode);

        const playButton = document.createElement('div');

        playButton.setAttribute('class', 'play');
        div.appendChild(playButton);
        div.onclick = function () {
          labnolIframe(this);
        };

        playerElements[n].appendChild(div);
      }
    }
  };


  const capitalize = (text) =>
    text.charAt(0).toUpperCase() + text.substring(1);


  const diffIcon = (diff) =>
    diff > 0
      ? <span className="increase">&uarr;</span>
      : (diff < 0 ? <span className="decrease">&darr;</span> : <span className="indentation" />);


  const videoFrame = (url) => {
    const videoId = url.split('/').pop();

    return <div className="youtube-player" data-id={videoId} />;
  };


  if (showSeo && !streetName) {
    return (
      <div className="bottom-seo container">
        { showRating ? (
          <div className="lv-header__rating-wrapper">
            <div className="container">
              <div className="lv-header__rating-container">

                <span className="lv-header__rating-headline">
                  {ratingHeadline}
                </span>

                <div className="lv-header__rating-stars">
                  <span
                    className="lv-header__rating-star"
                    id="1"
                    onClick={(e) => updateAreaRating(e.target.id)}
                    onMouseOver={handleRatingHover}
                    onMouseOut={handleRatingOut}>
                    <SvgIco name="star" size={20} />
                  </span>

                  <span
                    className="lv-header__rating-star"
                    id="2"
                    onClick={(e) => updateAreaRating(e.target.id)}
                    onMouseOver={handleRatingHover}
                    onMouseOut={handleRatingOut}>
                    { !hoveredRatingStar
                      ? <SvgIco name={ currentScore >= 2 ? 'star' : currentScore > 1 ? 'star-half' : 'star-outlined' } size={20} />
                      : <SvgIco name={ hoveredRatingStar >= 2 ? 'star' : 'star-outlined' } size={20} />
                    }
                  </span>
                  <span
                    className="lv-header__rating-star"
                    id="3"
                    onClick={(e) => updateAreaRating(e.target.id)}
                    onMouseOver={handleRatingHover}
                    onMouseOut={handleRatingOut}>
                    { !hoveredRatingStar
                      ? <SvgIco name={ currentScore >= 3 ? 'star' : currentScore > 2 ? 'star-half' : 'star-outlined' } size={20} />
                      : <SvgIco name={ hoveredRatingStar >= 3 ? 'star' : 'star-outlined' } size={20} />
                    }
                  </span>
                  <span
                    className="lv-header__rating-star"
                    id="4"
                    onClick={(e) => updateAreaRating(e.target.id)}
                    onMouseOver={handleRatingHover}
                    onMouseOut={handleRatingOut}
                  >
                    { !hoveredRatingStar
                      ? <SvgIco name={ currentScore >= 4 ? 'star' : currentScore > 3 ? 'star-half' : 'star-outlined' } size={20} />
                      : <SvgIco name={ hoveredRatingStar >= 4 ? 'star' : 'star-outlined' } size={20} />
                    }
                  </span>
                  <span
                    className="lv-header__rating-star"
                    id="5"
                    onClick={(e) => updateAreaRating(e.target.id)}
                    onMouseOver={handleRatingHover}
                    onMouseOut={handleRatingOut}
                  >
                    { !hoveredRatingStar
                      ? <SvgIco name={ currentScore === 5 ? 'star' : currentScore > 4 ? 'star-half' : 'star-outlined' } size={20} />
                      : <SvgIco name={ hoveredRatingStar >= 5 ? 'star' : 'star-outlined' } size={20} />
                    }
                  </span>
                  <span className="lv-header__rating-text">
                    { currentScore + ` (${votesCount} ${I18n.t('generic.star_rating_votes')})`}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ) : null }

        { descriptionWithAssets && descriptionWithAssets.length > 0
          ?
            <div id="seo-text-description">
              { descriptionWithAssets.map((section, index) => (
                <div className="bottom-seo__section row" key={index}>
                  <h2 className="bottom-seo__headline col-12 col-md-12 col-lg-8 col-xl-8">
                    { section.headline }
                  </h2>
                  <div className="bottom-seo__section-content col-xl-12">
                    { section.asset &&
                      section.asset.url &&
                      (section.asset.type === 'img' ||
                        section.asset.type === 'map') && (
                        <img
                          className="bottom-seo__img lazyload"
                          data-src={section.asset.url}
                          alt={section.asset.alt}
                          data-sizes="auto"
                          data-expand="100"
                        />
                      )}
                    <div className="bottom-seo__text-content" dangerouslySetInnerHTML={{ __html: section.text }} />
                    { section.asset &&
                      section.asset.url &&
                      section.asset.type === 'video' && (
                        <div className="bottom-seo__video-wrapper">
                          { videoFrame(section.asset.url) }
                        </div>
                      )}
                  </div>
                </div>
              ))}
            </div>
          : extendedDescription &&
              <div id="seo-text-description" className="description-padding">
                <div dangerouslySetInnerHTML={{ __html: extendedDescription }} />
              </div>
        }

        { seoStatistics && (
          <div className="bottom-seo__price section row">
            <h2 className="bottom-seo__headline col-12 col-md-12 col-lg-8 col-xl-8">
              { isCoworkingDk
                ? I18n.t(
                    'apps.lb_showroom.bottom_seo.headline_coworking_dk',
                    { location_kinds: translatedKinds, location: geoName },
                  )
                : I18n.t('apps.lb_showroom.bottom_seo.table_headline', {
                    location_kinds: translatedKinds,
                    location: geoName,
                  })
              }
            </h2>

            <div className="container">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-12 col-xl-8">
                  <p className="bottom-seo__price-table-description">
                    { isCoworkingDk
                      ? I18n.t(
                          'apps.lb_showroom.bottom_seo.headline_paragraph_coworking_dk',
                          {
                            location_kinds: translatedKinds,
                            location: geoName,
                          },
                        )
                      : I18n.t(
                          'apps.lb_showroom.bottom_seo.table_headline_paragraph',
                          {
                            location_kinds: translatedKinds,
                            location: geoName,
                          },
                    )}
                  </p>
                </div>
              </div>
            </div>

            <div className="bottom-seo__price-left-part col-12 col-md-12 col-lg-12 col-xl-8">
              <div className="bottom-seo__table-wrapper">
                <table className="bottom-seo__table">
                  <tbody>
                    <tr>
                      <th scope="col" className="main-header">
                        { capitalize(translatedKinds) }: {geoName}
                      </th>
                      <th scope="col" className="headers text-right">
                        { seoStatistics.headline[0] }
                      </th>
                    </tr>
                    { seoStatistics.rowsContent.map((content, indexRows) => (
                      <tr key={indexRows}>
                        <th scope="row">{content.description}</th>
                        { content.cells.map(
                          (cell, index) =>
                            index === 0 && <td key={index}>{cell}</td>,
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>

                <table className="bottom-seo__table bottom-seo__table--desktop">
                  <tbody>

                    <tr>
                      <th scope="col" className="main-header">
                        { capitalize(translatedKinds) }: { geoName }
                      </th>

                      { seoStatistics.headline.map((headline, index) => (
                        <th scope="col" key={index}>
                          { index === seoStatistics.headline.length - 1
                            ?
                              <Fragment>
                                <span>
                                  { I18n.t('apps.lb_showroom.bottom_seo.table_change') }
                                </span>
                                <span className="bottom-seo__change-headline">
                                  ({ headline })
                                </span>
                              </Fragment>
                            :
                              headline
                          }
                        </th>
                      ))}
                    </tr>

                    { seoStatistics.rowsContent.map((content, indexRows) => (
                      <tr key={indexRows}>
                        <th scope="row">{content.description}</th>
                        { content.cells.map((cell, index) =>
                          index === content.cells.length - 1
                            ?
                              <td key={index}>
                                { cell } { diffIcon(content.percentageChange) }
                              </td>
                            :
                              <td key={index}>{cell}</td>
                          ,
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <p
                className="bottom-seo__price-full-overview"
                dangerouslySetInnerHTML={{
                  __html: availableLocationsLinkI18n,
                }}
              ></p>
            </div>

            <div className="bottom-seo__table bottom-seo__table--links col-12 col-md-12 col-lg-12 col-xl-4">
              <h3 className="bottom-seo__links-headline">
                { topVievedLocationsHeadline }
              </h3>
              <ol className="bottom-seo__links">
                { seoStatistics.topViewed.map((link, index) => (
                  <li key={index}>
                    <a href={link[1]} target="_blank">
                      { link[0] }
                    </a>
                  </li>
                ))}
              </ol>
              <p className="bottom-seo__most-viewed-overview-link">
                <a href={seoStatistics.mostViewedOverviewLink} target="_blank">
                  { I18n.t('apps.lb_showroom.bottom_seo.viewed_locations_link', { location_kinds: declinedLocationKinds }) }
                </a>
                { I18n.t('apps.lb_showroom.bottom_seo.viewed_locations_p', { location: geoName }) }
              </p>
            </div>
          </div>
        )}
      </div>
    );
  } else {
    return null;
  }
}

export default BottomSeo;

BottomSeo.propTypes = {
  showSeo: PropTypes.bool,
};

BottomSeo.defaultProps = {
  showSeo: true,
};
