import React, { Component, Fragment } from 'react';
import update from 'immutability-helper';
import classNames from 'classnames';

import { SvgIco } from '../../SharedComponents/Icons';

import './index.sass';


class AreaFilter extends Component {

  state = {
    isOpened: this.props.isOpened || false,
    selectedSectionIds: [],
    searchQuery: '',
    toggleIsSticky: false,
    queryData: [],
  };

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside)
  }

  componentDidUpdate(prevProps, prevState) {
    const { scrollToggle } = this.props;

    scrollToggle && prevProps.scrollToggle !== scrollToggle && this.handleBodyScroll();
  }


  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    const isOutsideClick = event.target.tagName !== 'svg' && this.wrapperRef && !this.wrapperRef.contains(event.target);
    const isTrigger = event.target === this.trigger || this.trigger.contains(event.target);

    isOutsideClick && !isTrigger && this.setState({ isOpened: false, selectedSectionIds: [] });
  };

  selectAll = (province) => {
    // this.state.selectedSectionIds.indexOf(province.id) < 0 && this.setState({ selectedSectionIds: update(this.state.selectedSectionIds, {$push: [province.id]})});
    this.props.selectAll(province, this.props.dynamicData);
  };

  selectAction = (itemId, areaId) =>
    this.props.selectAction(itemId, areaId);

  setWrapperRef = (node) =>
    this.wrapperRef = node;

  handleOpening = () =>
    this.setState({ isOpened: !this.state.isOpened, selectedSectionIds: [] });

  toggleSelectSection = (id) => {
    const { searchQuery, selectedSectionIds } = this.state;
    const index = selectedSectionIds.indexOf(id);
    const sectionIsSelected = index >= 0;
    const newSelectedSections =
      searchQuery
        ? (sectionIsSelected ? update(selectedSectionIds, {$splice: [[index, 1]] }) : update(selectedSectionIds, {$push: [id]}))
        : sectionIsSelected ? [] : [id];

    this.setState({ selectedSectionIds: newSelectedSections }, () => !searchQuery && !sectionIsSelected && setTimeout(() => this.scrollToTarget(id), 300));
  };

  scrollToTarget = (id) => {
    const { isMobileMenu } = this.props;
    const scrollBody = document.getElementById(isMobileMenu ? 'filtersWrapper' : 'dropdown-body');
    const scrollTarget = document.getElementById(`trigger-${id}`);
    const dropdownBody = document.getElementById('dropdown-body');
    const scrollTargetTop = scrollTarget.offsetTop + (isMobileMenu ? dropdownBody.offsetTop : 0);

    scrollBody.scrollTop = scrollTargetTop;
  };

  handleSearch = (query) => {
    this.setState({ searchQuery: query.toLowerCase(), selectedSectionIds: [] }, () => {
      query
        ? this.handleQueryData()
        : this.setState({ queryData: [] });
    });
  };

  handleQueryData = () => {
    const { searchQuery } = this.state;
    const { dynamicData } = this.props;
    const openedSections = [];

    const queryData = searchQuery && dynamicData.filter(data => {
      const areasHasQuery = data.areas.some(area => area.name.toLowerCase().includes(searchQuery) && area.total_sum > 0);

      return !!(data.name.toLowerCase().includes(searchQuery) || areasHasQuery) && data.count > 0;
    }).map(filteredData => {
      const district = { ...filteredData };

      district.areas = filteredData.areas.filter(area => area.name.toLowerCase().includes(searchQuery) && area.total_sum > 0);
      district.areas.length && openedSections.push(district.id);

      return district;
    });

    this.setState({ queryData, selectedSectionIds: openedSections });
  };

  handleBodyScroll = () => {
    const { searchQuery, selectedSectionIds, toggleIsSticky } = this.state;

    if (!searchQuery && selectedSectionIds.length) {
      const { isMobileMenu } = this.props;
      const scrollTarget = document.getElementById(`body-scroll-target-${selectedSectionIds[0]}`);
      const dropdownBody = document.getElementById(isMobileMenu ? 'filtersWrapper' : 'dropdown-body');

      if (scrollTarget && dropdownBody) {
        const targetRect = scrollTarget && scrollTarget.getBoundingClientRect();
        const bodyRect = dropdownBody && dropdownBody.getBoundingClientRect();
        const targetTop = targetRect.top;
        const bodyTop = isMobileMenu ? bodyRect.top + 55 : bodyRect.top;
        const targetBottom = targetRect.bottom;
        const bodyBottom = bodyRect.bottom;
        const viewportBottom = window.innerHeight;
        const isSticky = targetTop <= bodyTop && (targetBottom > bodyBottom || targetBottom > viewportBottom);

        !isSticky && toggleIsSticky && this.setState({ toggleIsSticky: false });
        isSticky && !toggleIsSticky && this.setState({ toggleIsSticky: true });
      }
    }
  };


  render() {
    const { isOpened, searchQuery, selectedSectionIds, toggleIsSticky, queryData } = this.state;
    const { deselectAll, dynamicData, placeholder, selectedData, isMobileMenu } = this.props;
    const actionIconClasses = classNames('dropdown-selector__action-icon', { 'open': isOpened });
    const bodyClasses = classNames('dropdown-selector__body', { 'dropdown-selector__body--open': isOpened });
    const placeholderClasses = classNames('dropdown-selector__placeholder', { 'active': isOpened });
    const triggerClasses = classNames('dropdown-selector__trigger', { 'dropdown-selector__trigger--active': isOpened });

    let names = [];
    let selectedIds = [];
    Object.keys(selectedData).map(data => selectedIds = [...selectedIds, ...selectedData[data]]);
    selectedIds.forEach(selectedDistrictId => dynamicData.forEach(area => area.areas.forEach(district => district.id === selectedDistrictId && names.push(district.name))));

    const data = searchQuery ? queryData : dynamicData;

    const highlightSearchOutput = (name) => {
      if (name.toLowerCase().includes(searchQuery)) {
        const originName = name.toLowerCase();
        const startIndex = originName.indexOf(searchQuery);
        const endIndex = originName.indexOf(searchQuery) + searchQuery.length;
        const originSubstring = `<strong>${ name.substring(startIndex, endIndex) }</strong>`;
        const highlightedOutput = name.replace(name.substring(startIndex, endIndex), originSubstring).replaceAll(' ', '&nbsp');

        return highlightedOutput;
      } else {
        return name;
      }
    };



    return (
      <div className='dropdown-selector'>
        <div className={triggerClasses} onClick={this.handleOpening} ref={el => this.trigger = el}>
          <div className={placeholderClasses}>
            { placeholder }
            <span className="filter-toggle__select-indicator">
              { names.length > 0 && ` (${names.length})` }
            </span>
          </div>
          <div className="dropdown-selector__trigger-right">
            { names.length > 0 &&
              <div className="dropdown-selector__selected-tooltip">
                { names.join(', ') }
              </div>
            }
            <div className={actionIconClasses}>
              { !isOpened ? <SvgIco name="expand-more" size={20} /> : <SvgIco name="expand-less" size={20} /> }
            </div>
          </div>
        </div>

        { (isOpened || isMobileMenu) &&
          <div className={bodyClasses} ref={this.setWrapperRef} onScroll={this.handleBodyScroll} id="dropdown-body">

            <div className="dropdown__search-wrapper">
              <SvgIco name="search" size={20} />
              <input
                className="dropdown__search-input"
                onChange={(e) => this.handleSearch(e.target.value)}
                placeholder={`${I18n.t('apps.lb_showroom.showroom.area_filter.search_label')}...`}
                type="text"
                value={searchQuery}
              />
            </div>

            <div className='dropdown-selector__column'>
              { data.map((item) => {
                const sectionIsSelected = selectedSectionIds.indexOf(item.id) >= 0;
                const sortedAreasArray = item.areas.sort((a,b) => a.name.localeCompare(b.name));
                const areaIsSelected = selectedData[item.id] && selectedData[item.id].length > 0;
                const checkboxClasses = areaIsSelected ? 'dropdown-selector__item-icon-checked' : 'dropdown-selector__item-checkbox';
                const submenuClasses = classNames('dropdown-selector__item-submenu', {'opened': sectionIsSelected});
                const submenuStyles = sectionIsSelected ? {maxHeight: `${(item.areas.length + 1) * 60}px`} : {};
                const itemClasses = classNames('dropdown-selector__item main',
                  { 'dropdown-selector__item--selected': sectionIsSelected },
                  { 'sticky': toggleIsSticky && sectionIsSelected },
                  { 'dropdown-selector__item--active': selectedData[item.id] });

                return (
                  <div key={item.id} id={`body-scroll-target-${item.id}`}>
                    { item.count > 0 && (
                      <div className={itemClasses} id={`trigger-${item.id}`}>
                        <div className='dropdown-selector__item-name'>
                          <div onClick={ areaIsSelected ? () => deselectAll(item.id) : () => this.selectAll(item)} className={checkboxClasses}>
                            <SvgIco name="check" size={18} />
                          </div>
                          <div className="dropdown-selector__item-label" onClick={() => sortedAreasArray.length ? this.toggleSelectSection(item.id) : null}>
                            <span className={`${searchQuery ? 'with-query' : ''}`} dangerouslySetInnerHTML={{ __html: `${ searchQuery ? highlightSearchOutput(item.name) : item.name } (${item.count})` }} />
                            { areaIsSelected &&
                              <span className="dropdown-selector__chosen-tooltip"> { selectedData[item.id].length } {I18n.t('lb_showroom.showroom.list.chosen')}</span>
                            }
                          </div>
                        </div>
                        <span className={`arrow-down ${!sortedAreasArray.length ? 'disabled' : ''}`} onClick={() => this.toggleSelectSection(item.id)}>
                          { sectionIsSelected ? <SvgIco name="expand-less" size={20} /> : <SvgIco name="expand-more" size={20} /> }
                        </span>
                      </div>
                    )}

                    <div className={submenuClasses} style={submenuStyles}>
                      <div className="dropdown-selector__select-buttons">
                        <p onClick={() => this.selectAll(item)}>{ I18n.t('search_agent.select_all') }</p>
                        <p onClick={() => deselectAll(item.id)}>{ I18n.t('search_agent.deselect_all') }</p>
                      </div>

                      { sortedAreasArray.map(area => {
                        const areaIsSelected = selectedData[item.id] && selectedData[item.id].indexOf(area.id) >= 0;
                        const submenuItemClasses = classNames('dropdown-selector__item', {'dropdown-selector__item--checked': areaIsSelected});
                        const submenuCheckboxClasses = areaIsSelected ? 'dropdown-selector__item-icon-checked' : 'dropdown-selector__item-checkbox';


                        return (
                          <Fragment key={area.id}>
                            { area.total_sum > 0 && (
                              <div className={submenuItemClasses} onClick={() => this.selectAction(item.id, area.id)}>
                                <div className='dropdown-selector__item-icon'>
                                  <div className={submenuCheckboxClasses}>
                                    <SvgIco name="check" size={18} />
                                  </div>
                                </div>
                                <div className='dropdown-selector__item-name'
                                     dangerouslySetInnerHTML={{ __html: `${searchQuery ? highlightSearchOutput(area.name) : area.name} (${area.total_sum})` }}>
                                </div>
                              </div>)
                            }
                          </Fragment>
                        )
                      })}
                      <div className="dropdown-selector__separator"/>
                    </div>
                  </div>
                )
              })}
              { searchQuery && !data.length &&
                <div className="no-search-results">
                  <div className="no-search-results__header">
                    <SvgIco name="search_off" size={20} />
                    <p>{ I18n.t('apps.lb_showroom.showroom.area_filter.no_results.first') }</p>
                  </div>
                  <div className="no-search-results__subheader">
                    { I18n.t('apps.lb_showroom.showroom.area_filter.no_results.second') }
                  </div>
                </div>
              }
            </div>
            <div className="dropdown-selector__separator"/>
          </div>
        }

      </div>
    );
  }
}

export default AreaFilter;
