import React, { createContext, useEffect, useState, Suspense } from 'react';
import queryString from 'query-string';
import smoothscroll from 'smoothscroll-polyfill';
import PropTypes from "prop-types";

import { queryParseHelper } from '../../utils/queryParseHelper';
import httpService, { requestBuilder } from "../../api/httpService";
import { apiProvider } from "../../api/core";
import { queryBuilder } from "../../utils/urlQueryBuilder";
import { checkOrderedLocation } from "../helpers/checkOrderedLocation";
import { loadTawkTo } from "../TawkTo/TawkTo";
import SearchAgentBanner from "../SharedComponents/SearchAgentBanner";
import { ToTheTopBtn } from "../SharedComponents/Buttons";
import FAQSection from "../SharedComponents/FAQSection";
import Breadcrumb from "../Breadcrumb";
import BottomSeo from "./BottomSeo";
import HeaderSection from "./HeaderSection";
import MainFilter from "./MainFilter";
import SortBar from "./SortBar";
import LocationsContent from "./LocationsContent";
import { advertBannersData } from './data';

import '../SharedComponents/TrustLogos.sass';
import './index.sass';


const PopularAreas = React.lazy(() => import('../SharedComponents/PopularAreas'));
const ViewedLocations = React.lazy(() => import('./ViewedLocations'));


export const Context = createContext({});


/* <-----------------------    WHY-DID-YOU-RENDER - REACT DEBUGGER
(uncomment & copy => "static whyDidYouRender = true;" to the child component after state to see logs in console) */

/* if (process.env.NODE_ENV !== 'production') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, {
    // include: [/^pure/, /^Connect/, /^ConnectFunction/],
    // onlyLogs: true,
    titleColor: "green",
    diffNameColor: "aqua",
    logOnDifferentValues: true
  });
} */

// ------------------------------------------------>

let controller = null;
let signal = null;


const ListView = (props) => {

  const {
    breadcrumbs,
    headerContent,
    isRequestFromPrerender,
    keywords,
    popularDistricts,
    providersPage,
    richSnippetQuestions,
    siteKey,
    streetId
  } = props;

  const {
    countryAlpha2,
    defaultKinds,
    geoName,
    initialFacilities,
    initialPostalDistrictIds,
    initialSuitableForKinds,
    locationsFacilitiesPath,
    locationServiceUrl,
    locationsSuitableForKindsPath,
    locationsKindsUrl,
    locationsMapMarkersUrl,
    maxArea,
    newSearchAgentPath,
    postalDistrictServiceUrl,
    providerInfo,
    locationInfo,
    saleMaxPrice,
    sections,
    yearlyPerM2MaxPrice,
  } = keywords;

  const [comparableLocations, setComparableLocations] = useState({});
  const [headerData, setHeaderData] = useState({ ...headerContent, headline: geoName });
  const [energyRatings, setEnergyRatings] = useState([]);
  const [facilitiesList, setFacilitiesList] = useState([]);
  const [favouriteLocationsIds, setFavouriteLocationsIds] = useState([]);
  const [filterAreaList, setFilterAreaList] = useState([]);
  const [hoveredLocationId, setHoveredLocationId] = useState(null);
  const [initialKinds, setInitialKinds] = useState([]);
  const [initialPostalDistrictsIds, setInitialPostalDistrictsIds] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [listViewAsList, setListViewAsList] = useState(window.innerWidth >= 768);
  const [locationKinds, setLocationKinds] = useState([]);
  const [locationsByPostalDistricts, setLocationsByPostalDistricts] = useState([]);
  const [mapOptions, setMapOptions] = useState({ open: false, root: '', loaded: false });
  const [markersArray, setMarkersArray] = useState([]);
  const [nearbyLocationsLimit, setNearbyLocationsLimit] = useState(0);
  const [nearestSearchId, setNearestSearchId] = useState(null);
  const [onlyInternalLocations, setOnlyInternalLocations] = useState(true);
  const [orderedLocations, setOrderedLocations] = useState([]);
  const [preventRequestOnDragEnd, setPreventRequestOnDragEnd] = useState(true);
  const [selectedEnergyRatings, setSelectedEnergyRatings] = useState([]);
  const [selectedFacilities, setSelectedFacilities] = useState([]);
  const [selectedSuitableFor, setSelectedSuitableFor] = useState([]);
  const [serverErrorListView, setServerErrorListView] = useState('');
  const [showSeo, setShowSeo] = useState(true);
  // const [showSearchButton, setShowSearchButton] = useState(true);
  const [suitableForList, setSuitableForList] = useState([]);
  const [tawkIsLoaded, setTawkIsLoaded] = useState(false);
  const [totalLocationsCount, setTotalLocationsCount] = useState('');
  const [viewedLocations, setViewedLocations] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [requestParams, setRequestParams] = useState({
    initial_postal_district_id: headerContent.initialPostalDistrictId,
    initial_province_id: headerContent.initialProvinceId,
    sections,
    rent_min: 0,
    rent_max: sections.includes('lease') ? yearlyPerM2MaxPrice : saleMaxPrice,
    area_min: 0,
    area_max: maxArea,
    postal_districts: Object.keys(initialPostalDistrictIds).reduce((acc, province) => acc.concat(initialPostalDistrictIds[province]), []),
    page: 1,
    area_max_default: maxArea,
    rent_max_default: sections.includes('lease') ? yearlyPerM2MaxPrice : saleMaxPrice,
    kinds: defaultKinds || [],
    country_alpha2: countryAlpha2,
    uri_kind: defaultKinds[0] || '',
    energy_rating: [],
  });

  const suitableForStructuredTypes = {
    office: [ 'cafe', 'clinic', 'education', 'high_ceiling_storage', 'popup_store', 'production', 'showroom', 'storage_hotel', 'workshop' ],
    warehouse: [ 'clinic', 'education', 'high_ceiling_storage', 'popup_store', 'production', 'showroom', 'storage_hotel', 'workshop' ],
    coworking: [ 'clinic', 'education', 'production', 'showroom', 'storage_hotel', 'workshop' ],
    store: [ 'cafe', 'clinic', 'education', 'hair_salon', 'popup_store', 'showroom' ],
    business_center: [ 'clinic', 'education', 'popup_store', 'showroom' ],
  };

  const section = sections.includes('lease') ? 'lease' : 'sale';
  const mapIsOpen = mapOptions.open;


  useEffect(() => {
    const getPath = queryString.parse(window.location.search);
    const searchId = queryString.parse(window.location.search).search_id;
    let newRequestParams = { ...requestParams };

    streetId && (newRequestParams.street_id = streetId);
    providerInfo && (newRequestParams.provider_id = providerInfo.providerId);
    locationInfo && (newRequestParams.location_id = locationInfo.locationId);
    initialFacilities && initialFacilities.length && (newRequestParams.facilities = initialFacilities);
    initialSuitableForKinds && initialSuitableForKinds.length && (newRequestParams.suitable_for = initialSuitableForKinds);
    Object.keys(getPath).length > 0 && (newRequestParams = queryParseHelper(newRequestParams, getPath));

    setRequestParams(newRequestParams);
    setInitialKinds(requestParams.kinds);
    getInitialListLocationByDistricts(newRequestParams, searchId);
  }, []);


  useEffect(() => {
    smoothscroll.polyfill();
    window.innerWidth >= 992 && getViewedLocations();
    loadTawkTo(siteKey, setTawkIsLoaded(true));
  }, []);


  useEffect(() => {
    window.addEventListener('resize', calculateWindowWidth);
  }, []);


  useEffect(() => {
    if (localStorage) {
      const retrievedFavouriteLocations = localStorage.getItem(`my.favourite_${section}_${siteKey}`);
      const retrievedComparableLocations = localStorage.getItem(`my.comparable_${section}_${siteKey}`);
      const favouriteLocationsIds = JSON.parse(retrievedFavouriteLocations) || [];
      const comparableLocations = JSON.parse(retrievedComparableLocations) || {};

      localStorage.setItem('locale', props.locale);
      localStorage.getItem('viewedAsList') && localStorage.getItem('viewedAsList') === 'false' && setListViewAsList(false);
      localStorage.getItem('my.ordered_locations') ? setOrderedLocations(checkOrderedLocation) : localStorage.setItem('my.ordered_locations', JSON.stringify({}));
      setFavouriteLocationsIds(favouriteLocationsIds);
      setComparableLocations(comparableLocations);

      if (window.innerWidth <= 767) {
        setListViewAsList(false);
        localStorage.setItem('viewedAsList', 'false')
      }
    }

    if (sessionStorage.getItem('location_items.scroll_value')) {
      scrollTo({ top: sessionStorage.getItem('location_items.scroll_value'), left: document.body, behavior: "smooth" });
      sessionStorage.removeItem('location_items.scroll_value');
    }
  }, []);


  const calculateWindowWidth = () => setWindowWidth(window.innerWidth);


  const getViewedLocations = () => {
    const retrievedViewed = localStorage && localStorage.getItem(`my.viewed_${section}_${siteKey}`);
    const viewedLocationsIds = JSON.parse(retrievedViewed) || [];

    viewedLocationsIds.length && httpService
      .getLocationsByIds(viewedLocationsIds, viewedLocationsIds)
      .then((response) => {
        if (response) {
          response.length !== viewedLocationsIds.length
          && localStorage
          && localStorage.setItem(`my.viewed_${section}_${siteKey}`, JSON.stringify(response.map(location => `${location.id}`)));

          setViewedLocations(response);
        }
      }).catch(error => console.log('Error: ', error));
  };


  const getInitialListLocationByDistricts = (requestParams, searchId, onPopState) => {
    const newRequestParams = { ...requestParams };

    isLoaded && setIsLoaded(false);
    searchId && (newRequestParams.search_id = searchId);

    apiProvider
      .post(locationServiceUrl, requestBuilder(newRequestParams), true)
      .then(response => {
        if (response.error) {
          setServerErrorListView(response.status >= 500 ? 'server' : 'network');
        } else {

          const newPostalDistricts = response.postal_district_ids || requestParams.postal_districts;

          const callbackFunction = (response) => {
            response && response.locations && setLocationsByPostalDistricts(response.locations);
            setTotalLocationsCount(response.total);
            setIsLoaded(true);
            setShowSeo(response.show_seo);
            setSelectedSuitableFor(response.suitable_for_kinds || suitableForList);
            setSelectedFacilities(response.facilities || facilitiesList);
            setInitialPostalDistrictsIds(newPostalDistricts);
            setOnlyInternalLocations(response.only_internal_locations);
          };

          mapMarkers({ ...newRequestParams, postal_districts: newPostalDistricts });
          handleResponse(response, newRequestParams, callbackFunction, true);

          if (onPopState) {
            const dispatchList = [getKinds, getEnergyRatings, getFacilitiesList, getSuitableForKinds];

            updateFiltersOptions(dispatchList, newRequestParams, response.postal_district_ids);
          }
        }
      });
  };


  const getKinds = (requestParams) => {
    const { sections, postal_districts } = requestParams;

    apiProvider
      .post(locationsKindsUrl, requestBuilder({sections, postal_districts}))
      .then(response => response && setLocationKinds(response));
  };


  const getEnergyRatings = (requestParams) => {
    const { sections, postal_districts, kinds } = requestParams;

    apiProvider
      .post('/lease/api/frontend/locations/energy_ratings', requestBuilder({sections, postal_districts, kinds}))
      .then(response => response && setEnergyRatings(response));
  };


  const getFacilitiesList = (requestParams) => {
    const { sections, postal_districts, kinds } = requestParams;
    const newRequestParams = { sections, postal_districts, kinds };

    apiProvider
      .post(locationsFacilitiesPath, requestBuilder(newRequestParams))
      .then(response => response && setFacilitiesList(response));
  };


  const getSuitableForKinds = (requestParams) => {
    const { sections, postal_districts, kinds } = requestParams;
    const newRequestParams = { sections, postal_districts, kinds };

    apiProvider
      .post(locationsSuitableForKindsPath, requestBuilder(newRequestParams))
      .then(response => response && setSuitableForList(response));
  };


  const updateFiltersOptions = (updateFunctions, requestParams, postalDistrictIds) => {
    postalDistrictIds && (requestParams.postal_districts = postalDistrictIds);
    updateFunctions.map(func => func(requestParams));
  };


  const handleResponse = (response, requestParams, callbackFunction, isInitialRequest) => {
    const currentPage = requestParams.page;

    // Request nearby locations if total locations count < 24
    // We need to check current page in case user will manually input page # in url
    // to handle error in this case
    response.total < 24 && !requestParams.viewport_string
      ? currentPage <= 2 && getNearbyLocations(requestParams, response, () => callbackFunction(response))
      : callbackFunction(response);

    !isInitialRequest && setRequestParams(requestParams);
  };


  // Nearby locations should be requested in case no filters are selected and response.locations.length < 24
  // In this case we will join response.locations with nearby locations (and nearby locations banner)
  // in quantity we need for each page depends on the quantity of response.locations.
  // The total quantity of locations (response.locations + nearby locations) should be <= 24 (two standard pages 12 per page).
  // There might be cases when there's no nearby locations at all.
  const getNearbyLocations = (requestParams, response, callbackFunction) => {

    const { nearbyLocations: nearbyLocationsUrl } = keywords;
    const { locations, total: byPostalDistrictsLocationsTotalCount, postal_district_ids, loc_id_for_nearby_locations } = response;
    const initialLocationsPresent = !!locations.length;

    const {
      suitable_for,
      facilities,
      area_min,
      area_max,
      area_max_default,
      rent_min,
      rent_max,
      rent_max_default,
      kinds,
      page,
      per_page,
      uri_kind,
    } = requestParams;

    (page === 1 || initialLocationsPresent) && callbackFunction();

    const noFiltersSelected =
      (!facilities || !facilities.length)
      && (!suitable_for || !suitable_for.length)
      && area_min === 0
      && area_max === area_max_default
      && rent_min === 0
      && rent_max === rent_max_default
      && kinds.length === 0 || kinds.length === 1 && kinds[0] === uri_kind;

    // Show nearby locations only in case no filters are selected & it's not a provider list view page
    // Else clear nearby locations if present, dispatch response
    if (noFiltersSelected && !providersPage) {

      const totalLocationsLimit = 24;
      const location_id = loc_id_for_nearby_locations || (initialLocationsPresent ? locations[0].id : null);
      const perPage = per_page ? Number(per_page) : 12;
      const isSecondPage = perPage === 12 && Number(page) === 2;
      const firstPageNearbyLocationsLimit = byPostalDistrictsLocationsTotalCount < 12 ? 12 - byPostalDistrictsLocationsTotalCount : 0;
      let limit = totalLocationsLimit - byPostalDistrictsLocationsTotalCount;
      let offset = 0;

      if (isSecondPage) {
        limit = totalLocationsLimit - byPostalDistrictsLocationsTotalCount - firstPageNearbyLocationsLimit;
        offset = firstPageNearbyLocationsLimit;
      }

      const limitMathSign = Math.sign(limit);

      const params = {
        location_id,
        kinds: requestParams.kinds,
        limit: limitMathSign >= 0 ? limit : 0,
        section: requestParams.sections[0],
        country_alpha2: requestParams.country_alpha2,
        offset,
        postal_district_ids,
        facilities: [],
        suitable_for: [],
      };

      location_id
        ? apiProvider.withOptions({ url: nearbyLocationsUrl, method: 'get', withCredentials: true, params }, true)
          .then(data => {
            if (data.error) {
              (data.status >= 500) && console.log('error', data.error);
            } else {

              // In case of the first page we need to request nearby locations
              // with max limit, to receive the possible "total" count that can be found,
              // as we need to pass this number to pagination component and
              // understand if we need to show pagination(there are cases where there's no nearby locations at all).
              // In case of the second page we can limit location to required number.

              const { search_id, nearest_locations } = data;
              const locationsCount = nearest_locations.length;
              const nearbyLocationsLimit = isSecondPage ? locationsCount + offset : locationsCount;

              if (nearbyLocationsLimit) {
                const filteredLocations = locations.length ? locations.filter(location => !location.advertisement) : [];
                const nearestLocationsWithAdvert = [ ...nearest_locations ];

                nearestLocationsWithAdvert.push(advertBannersData.signUp);
                nearestLocationsWithAdvert.length > 2
                  ? nearestLocationsWithAdvert.splice(nearestLocationsWithAdvert.length - 2, 0, advertBannersData.searchAgent)
                  : nearestLocationsWithAdvert.unshift(advertBannersData.searchAgent);

                // Read the comment above
                const nearbyLocations =
                  isSecondPage || perPage !== 12
                    ? [ ...nearestLocationsWithAdvert ]
                    : nearestLocationsWithAdvert.splice(0, firstPageNearbyLocationsLimit);

                // If these are the first nearby locations - insert banner object
                offset === 0 && nearbyLocations.length && nearbyLocations.unshift({ id: 'nearByBanner' });
                setLocationsByPostalDistricts([ ...filteredLocations, ...nearbyLocations ]);
                setNearbyLocationsLimit(isSecondPage ? locationsCount + offset : locationsCount);
                setNearestSearchId(search_id);
                setTotalLocationsCount(byPostalDistrictsLocationsTotalCount);
                setIsLoaded(true);
              } else {
                clearNearbyLocations();
                callbackFunction();
              }
            }
          })
        : callbackFunction();
    } else {
      (nearbyLocationsLimit || nearestSearchId) && clearNearbyLocations();
      callbackFunction();
    }
  };


  const changePage = (page) => {

    const newRequestParams = { ...requestParams };

    isLoaded && setIsLoaded(false);
    newRequestParams.page = page;

    apiProvider
      .post(locationServiceUrl, requestBuilder({ ...newRequestParams, search_id: nearestSearchId || newRequestParams.search_id }), true)
      .then(response => {
        if (response.error) {
          setServerErrorListView(response.status >= 500 ? 'server' : 'network');
        } else {
          const formattedQueryString = () => {
            if (nearestSearchId) {
              const parsedQuery = queryString.parse(response.query_string);

              parsedQuery.search_id = nearestSearchId;
              return queryString.stringify(parsedQuery);
            } else {
              return response.query_string;
            }
          };

          queryBuilder(formattedQueryString());
          setTimeout(() => window.scroll(1, 0), 10);
          handleResponse(response, newRequestParams, handleReceivedLocationState);
        }
      });
  };


  const changePerPage = (perPage) => {
    const newRequestParams = { ...requestParams };

    isLoaded && setIsLoaded(false);
    newRequestParams.per_page = perPage;
    newRequestParams.page = 1;

    apiProvider
      .post(locationServiceUrl, requestBuilder(newRequestParams))
      .then(response => {
        if (response) {
          queryBuilder(response.query_string);
          handleResponse(response, newRequestParams, receivedSortedLocations);
        } else {
          setServerErrorListView('network');
        }
      });
  };


  const sortLocationsBy = (sortBy) => {
    const newRequestParams = { ...requestParams };

    isLoaded && setIsLoaded(false);
    newRequestParams.sort_by = sortBy;
    newRequestParams.page = 1;

    apiProvider
      .post(locationServiceUrl, requestBuilder(newRequestParams))
      .then(response => {
        if (response) {
          queryBuilder(response.query_string);
          handleResponse(response, newRequestParams, receivedSortedLocations);
        }
      });
  };


  const filterLocationsByPrice = (priceRange) => {
    const newRequestParams = { ...requestParams };

    isLoaded && setIsLoaded(false);
    newRequestParams.rent_min = priceRange.min;
    newRequestParams.rent_max = priceRange.max;
    newRequestParams.page = 1;
    mapIsOpen && mapMarkers(newRequestParams);

    apiProvider
      .post(locationServiceUrl, requestBuilder(newRequestParams))
      .then(response => {
        if (response) {
          queryBuilder(response.query_string);
          handleResponse(response, newRequestParams, handleReceivedLocationState);
        } else {
          setServerErrorListView('network');
        }
      });
  };


  const filterLocationsBySquare = (squareRange) => {
    const newRequestParams = { ...requestParams };

    isLoaded && setIsLoaded(false);
    newRequestParams.area_min = squareRange.min;
    newRequestParams.area_max = squareRange.max;
    newRequestParams.page = 1;
    mapIsOpen && mapMarkers(newRequestParams);

    apiProvider
      .post(locationServiceUrl, requestBuilder(newRequestParams))
      .then(response => {
        if (response) {
          queryBuilder(response.query_string);
          handleResponse(response, newRequestParams, handleReceivedLocationState);
        } else {
          setServerErrorListView('network');
        }
      });
  };


  const kindFilterToggle = (selectedKinds, requestParams) => {
    signal && controller.abort();

    const newRequestParams = { ...requestParams };

    controller = new AbortController();
    signal = controller.signal;

    isLoaded && setIsLoaded(false);
    newRequestParams.kinds = selectedKinds.map(elem => elem.id);
    newRequestParams.page = 1;
    mapIsOpen && mapMarkers(newRequestParams);

    apiProvider
      .post(locationServiceUrl, requestBuilder(newRequestParams), true, signal)
      .then(response => {

        controller = null;
        signal = null;

        if (response.error) {
          setServerErrorListView(response.status >= 500 ? 'server' : 'network');
        } else {
          const dispatchList = [getEnergyRatings, getFacilitiesList, getSuitableForKinds];

          queryBuilder(response.query_string);
          handleResponse(response, newRequestParams, handleReceivedLocationState);
          updateFiltersOptions(dispatchList, newRequestParams, response.postal_district_ids);
        }
      })
  };


  const getListLocationByDistrictsArea = (selectedAreasIds) => {
    const newRequestParams = { ...requestParams };

    isLoaded && setIsLoaded(false);

    // remove street_ when applied area filter
    delete newRequestParams.street_id
    newRequestParams.postal_districts = selectedAreasIds;
    newRequestParams.page = 1;

    apiProvider
      .post(locationServiceUrl, requestBuilder(newRequestParams), true)
      .then(response => {
        if (response.error) {
          setServerErrorListView(response.status >= 500 ? 'server' : 'network');
        } else {
          const dispatchList = [getKinds, getEnergyRatings, getFacilitiesList, getSuitableForKinds, (newParams) => setRequestParams(prevParams => ({ ...prevParams, ...newParams }))];

          queryBuilder(response.query_string);

          if (response.total < 24) {
            getNearbyLocations(newRequestParams, response, () => handleReceivedLocationState(response));
          } else {
            (nearbyLocationsLimit || nearestSearchId) && clearNearbyLocations();
            handleReceivedLocationState(response);
          }

          mapIsOpen && mapMarkers({ ...newRequestParams, postal_districts: response.postal_district_ids});
          updateFiltersOptions(dispatchList, newRequestParams, response.postal_district_ids);
        }
      });
  };


  const getListLocationMobileFilters = () => {
    const newRequestParams = { ...requestParams };

    isLoaded && setIsLoaded(false);
    newRequestParams.page = 1;
    mapIsOpen && mapMarkers(newRequestParams);

    apiProvider
      .post(locationServiceUrl, requestBuilder(newRequestParams))
      .then(response => {
        if (response) {
          queryBuilder(response.query_string);
          handleReceivedLocationState(response);
          setRequestParams(prevParams => ({ ...prevParams, ...newRequestParams }))
        } else {
          setServerErrorListView('network');
        }
      });
  };


  const mapMarkers = (requestParams) => {
    setPreventRequestOnDragEnd(true);

    apiProvider
      .post(locationsMapMarkersUrl, requestBuilder(requestParams))
      .then(response => response && setMarkersArray(response.locations));
  };


  const applySuitableForFilters = (selectedSuitableFor) => {
    const newRequestParams = { ...requestParams };

    isLoaded && setIsLoaded(false);
    newRequestParams.suitable_for = selectedSuitableFor;
    newRequestParams.page = 1;
    mapIsOpen && mapMarkers(newRequestParams);

    apiProvider
      .post(locationServiceUrl, requestBuilder(newRequestParams))
      .then(response => {
        if (response) {
          queryBuilder(response.query_string);
          handleResponse(response, newRequestParams, handleReceivedLocationState);
        }
      });
  };


  const handleEnergyRatingSelect = (selectedEnergyRatings) => {
    const newRequestParams = { ...requestParams };

    isLoaded && setIsLoaded(false);
    newRequestParams.energy_rating = selectedEnergyRatings;
    newRequestParams.page = 1;
    mapIsOpen && mapMarkers(newRequestParams);

    apiProvider
      .post(locationServiceUrl, requestBuilder(newRequestParams), true)
      .then(response => {
        if (response.error) {
          setServerErrorListView(response.status >= 500 ? 'server' : 'network');
        } else {
          queryBuilder(response.query_string);
          handleResponse(response, newRequestParams, handleReceivedLocationState);
        }
      });
  };


  const getLocationsByCoords = (viewport_string) => {
    const newRequestParams = { ...requestParams, postal_districts: [], viewport_string };

    isLoaded && setIsLoaded(false);
    newRequestParams.page = 1;

    apiProvider
      .post(locationServiceUrl, requestBuilder(newRequestParams), true)
      .then(response => {
        if (response.error) {
          setServerErrorListView(response.status >= 500 ? 'server' : 'network');
        } else {
          const dispatchList = [getKinds, getEnergyRatings, getFacilitiesList, getSuitableForKinds];

          queryBuilder(response.query_string);
          handleResponse(response, newRequestParams, handleReceivedLocationState);
          updateFiltersOptions(dispatchList, newRequestParams, response.postal_district_ids);
        }
      });
  };


  const applyFacilitiesFilters = (selectedFacilities) => {
    const newRequestParams = { ...requestParams };

    isLoaded && setIsLoaded(false);
    newRequestParams.facilities = selectedFacilities;
    newRequestParams.page = 1;
    mapIsOpen && mapMarkers(newRequestParams);

    apiProvider
      .post(locationServiceUrl, requestBuilder(newRequestParams))
      .then(response => {
        if (response) {
          queryBuilder(response.query_string);
          handleResponse(response, newRequestParams, handleReceivedLocationState);
        }
      });
  };


  const filterAreas = () => {
    const searchData = queryString.stringify({
      "country_alpha2": countryAlpha2,
      "sections[]": sections,
    });

    apiProvider
      .getAll(`${postalDistrictServiceUrl}?${searchData}`)
      .then(response => response && setFilterAreaList(response || filterAreaList));
  };


  const toggleMap = (root) => {
    if (mapIsOpen) {
      // setMarkersArray([]);
      setMapOptions((prevOptions) => ({ ...prevOptions, open: false, root: '', }));
    } else {
      setMapOptions({ open: true, root, loaded: true });
    }
  };


  const receivedSortedLocations = (data) => {
    setLocationsByPostalDistricts(data.locations);
    setIsLoaded(true);
  };


  const handleReceivedLocationState = (data) => {
    setLocationsByPostalDistricts(data.locations);
    setTotalLocationsCount(data.total);
    setShowSeo(data.showSeo);
    setIsLoaded(true);
    setOnlyInternalLocations(data.only_internal_locations);
  };


  const clearNearbyLocations = () => {
    setNearbyLocationsLimit(null);
    setNearestSearchId('');
  };


  const state = {
    ...props,
    comparableLocations,
    energyRatings,
    facilitiesList,
    favouriteLocationsIds,
    filterAreaList,
    headerData,
    hoveredLocationId,
    initialKinds,
    initialPostalDistrictsIds,
    isLoaded,
    listViewAsList,
    locationKinds,
    locationsByPostalDistricts,
    mapMarkers,
    mapOptions,
    markersArray,
    nearbyLocationsLimit,
    onlyInternalLocations,
    orderedLocations,
    preventRequestOnDragEnd,
    requestParams,
    section,
    selectedEnergyRatings,
    selectedFacilities,
    selectedSuitableFor,
    serverErrorListView,
    setComparableLocations,
    setEnergyRatings,
    setFacilitiesList,
    setFavouriteLocationsIds,
    setFilterAreaList,
    setHeaderData,
    setHoveredLocationId,
    setInitialPostalDistrictsIds,
    setIsLoaded,
    setListViewAsList,
    setLocationKinds,
    setLocationsByPostalDistricts,
    setMarkersArray,
    setNearbyLocationsLimit,
    setOnlyInternalLocations,
    setOrderedLocations,
    setPreventRequestOnDragEnd,
    setRequestParams,
    setSelectedEnergyRatings,
    setSelectedFacilities,
    setSelectedSuitableFor,
    setServerErrorListView,
    setShowSeo,
    setSuitableForList,
    setTotalLocationsCount,
    showSeo,
    suitableForList,
    suitableForStructuredTypes,
    viewedLocations,
    tawkIsLoaded,
    totalLocationsCount,
    windowWidth,
    applyFacilitiesFilters,
    applySuitableForFilters,
    changePage,
    changePerPage,
    filterAreas,
    filterLocationsByPrice,
    filterLocationsBySquare,
    getInitialListLocationByDistricts,
    getEnergyRatings,
    getKinds,
    getFacilitiesList,
    getListLocationByDistrictsArea,
    getListLocationMobileFilters,
    getLocationsByCoords,
    getSuitableForKinds,
    getViewedLocations,
    handleEnergyRatingSelect,
    kindFilterToggle,
    sortLocationsBy,
    toggleMap,
  };


  return (
    <Context.Provider value={state}>
      <div className="list-view">

        <Breadcrumb breadcrumbs={breadcrumbs} root={'breadcrumbs-list-view'}/>

        <HeaderSection />

        <MainFilter />

        <SortBar />

        <LocationsContent />

        { viewedLocations && viewedLocations.length > 0 &&
          <Suspense fallback={''}>
            <ViewedLocations />
          </Suspense>
        }

        <SearchAgentBanner
          newSearchAgentPath={newSearchAgentPath}
          searchAgentText={I18n.t('lb_showroom.detailed.search_agent.description')}
          searchAgentHeadline={I18n.t('lb_showroom.detailed.search_agent.headline')}
          siteKey={siteKey}
        />

        { !!(popularDistricts && popularDistricts.length) &&
          <Suspense fallback={''}>
            <PopularAreas
              isRequestFromPrerender={isRequestFromPrerender}
              popularDistricts={popularDistricts}
            />
          </Suspense>
        }

        <BottomSeo />

        <ToTheTopBtn />

        { richSnippetQuestions && richSnippetQuestions.length > 0 &&
          <FAQSection
            hidden={!showSeo}
            indentation={0}
            isRequestFromPrerender={isRequestFromPrerender}
            richSnippetQuestions={richSnippetQuestions}
            title={I18n.t('apps.lb_showroom.detailed.tabs.faq_title')}
          />
        }

      </div>
    </Context.Provider>
  );
};


export default ListView;


ListView.propTypes = {
  richSnippetQuestions: PropTypes.array,
};

ListView.defaultProps = {
  richSnippetQuestions: [],
};
