import React, { Fragment, Suspense, useContext, useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { debounce } from 'throttle-debounce';
import { isMobileOnly } from 'react-device-detect';

import { SvgIco } from '../../SharedComponents/Icons';
import { Context } from "../index";

import './index.sass';

const MapSection = React.lazy(() => import('../MapSection'));
const GridViewItemList = React.lazy(() => import('../GridViewItem/GridViewItemList'));
const ListViewItemsList = React.lazy(() => import('../ListViewItems/ListViewItemsList'));


const LocationsContent = () => {

  const {
    changePage,
    hoveredLocationId,
    isLoaded,
    keywords,
    listViewAsList,
    locationsByPostalDistricts,
    mapOptions,
    nearbyLocationsLimit,
    requestParams,
    serverErrorListView,
    setHoveredLocationId,
    totalLocationsCount,
    windowWidth,
  } = useContext(Context);

  const [showBottomPagination, setShowBottomPagination] = useState(false);

  const { newSearchAgentPath } = keywords;


  useEffect(() => {
    window.addEventListener('scroll', debounce(15, () => handleBottomPagination()));
  }, []);


  const handleBottomPagination = () => {
    const paginationElem = document.getElementById('main-pagination');
    const paginationBottom = paginationElem && paginationElem.getBoundingClientRect().bottom;
    const currentScreenTop = window.screenTop;

    currentScreenTop > paginationBottom
      ? setShowBottomPagination(true)
      : setShowBottomPagination(false);
  };


  const handlePageClick = (data) => {
    changePage(data.selected + 1);
    setTimeout(() => window.scrollTo(0, 0), 10);
  };


  const generatePaginationLink = (page) => {
    const { origin, pathname } = window.location;

    return `${origin}${pathname}${page > 1 ? `?page=${page}` : ''}`;
  };


  const defaultItemsPerPage = 12;
  const mapIsOpen = mapOptions.open;
  const mapLoaded = mapOptions.loaded;
  const perPage = requestParams.per_page ? requestParams.per_page : defaultItemsPerPage;
  const initialPage = requestParams.page ? (Number(requestParams.page) - 1) : 1;
  const showPagination = locationsByPostalDistricts && locationsByPostalDistricts.length > 0 && totalLocationsCount + nearbyLocationsLimit > perPage;
  const mapClass = `list-view-map ${ mapOptions.root } ${mapIsOpen ? 'col-12 col-lg-4' : 'hidden'}`;
  const styles = {
    spinnerMargin: !listViewAsList ? { paddingBottom: '30px' } : null,
    containerMargin: (listViewAsList && showPagination) ?  { marginTop: '32px' } : {marginTop: '64px'}
  };

  const SkeletonLoader = ({ className, count }) =>
    <div className={`skeleton-box-wrapper ${className}`}>
      { Array.apply('test', Array(count)).map((elem, index) =>
        <div className={`skeleton-box skeleton-box--${index + 1}`} key={className + index} />
      )}
    </div>;


  const skeletonGridViewLoader =
    <div className="grid-list container max-width">
      <div className="row">
        { Array.apply('test', Array(perPage)).map((key, index) =>
          <div className={`grid-view-item-loader-wrapper col-md-6 col-lg-${mapIsOpen ? 6 : 4} col-xl-${mapIsOpen ? 4 : 3}`} key={index + 'skeleton-grid'}>
            <SkeletonLoader className={`grid-view-item-loader`} count={12} key={index}/>
          </div>
        )}
      </div>
    </div>;


  const skeletonListViewLoader =
    <div className="list-view__list-as-list">
      { Array.apply('test', Array(perPage)).map((key, index) =>
        <div className="container list-item max-width" key={index + 'skeleton-list'}>
          <div className="list-item__wrapper row skeleton">
            <div className="list-item__image-slider skeleton col-xl-3 col-lg-3 col-md-4 col-3">
              <SkeletonLoader className={`list-view-skeleton-loader`} count={1} key={index}/>
            </div>
            <div className="list-item__description-wrapper col-xl-6 col-lg-6 col-md-4 col-sm-9 col-9">
              <SkeletonLoader className={`list-view-skeleton-loader`} count={6} key={index + 1000}/>
            </div>
            <div className="list-item__metrics-wrapper skeleton mobile-hidden col-lg-3 col-md-4">
              <SkeletonLoader className={`list-view-skeleton-loader`} count={7} key={index +2000}/>
            </div>
          </div>
        </div>
      )}
    </div>;


  const locationsAsGrid =
    <Suspense fallback={skeletonGridViewLoader}>
      <GridViewItemList
        hoveredLocationId={hoveredLocationId}
        setHoveredLocationId={setHoveredLocationId}
      />
    </Suspense>;


  const locationsAsList =
    <Fragment>
      { windowWidth >= 768
        ? <Suspense fallback={skeletonListViewLoader}>
            <ListViewItemsList />
          </Suspense>
        : locationsAsGrid
      }
    </Fragment>;


  const pagination = showPagination &&
    <div className="pagination-wrapper">
      <ReactPaginate
        activeClassName={'active'}
        breakLabel={'...'}
        containerClassName={'pagination'}
        marginPagesDisplayed={1}
        forcePage={initialPage}
        nextLabel={<SvgIco name="keyboard-arrow-right-rounded-ios" size={16} />}
        previousLabel={<SvgIco name="keyboard-arrow-left-rounded-ios" size={16} />}
        onPageChange={handlePageClick}
        pageCount={Math.ceil((totalLocationsCount + nearbyLocationsLimit) / perPage)}
        pageRangeDisplayed={isMobileOnly ? 1 : 2}
        hrefBuilder={(page) => generatePaginationLink(page)}
      />
    </div>;


  return (
    <div className={`list-view-container ${mapIsOpen ? 'row' : ''}`}>

      <div className={`list-view-main-content ${mapIsOpen ? 'with-map col-12 col-lg-8' : ''}`}>

        { isLoaded
          ? ( locationsByPostalDistricts.length
              ?
                ( listViewAsList && !mapIsOpen ? locationsAsList : locationsAsGrid )
              :
                <div className={`no-locations-found no-found-text container ${mapIsOpen ? 'map-open' : ''}`}>
                  <div className="col-md-10 col-lg-8 offset-md-1 offset-lg-2 ">
                    <p className="title">{ I18n.t('apps.lb_showroom.seach_area.no_results_title') }</p>
                    <p className="description" dangerouslySetInnerHTML={{__html: I18n.t('apps.lb_showroom.seach_area.no_results_description', {"search_agent_link": newSearchAgentPath}) }} />
                  </div>
                </div>
            )
          : ( serverErrorListView
              ?
                <p className={`no-locations-found ${mapIsOpen ? 'map-open' : ''}`}>
                  { serverErrorListView === 'server'
                    ? I18n.t('generic.network-error')
                    : I18n.t('generic.internet-issue-error')
                  }
                  <a href={window.location.href}>
                    { I18n.t('general.refresh-page') }
                  </a>
                </p>
              : !listViewAsList && !mapIsOpen
              ? skeletonGridViewLoader
              : skeletonListViewLoader
            )
        }

        <div className="container" style={styles.containerMargin} id="main-pagination">
          { pagination }
        </div>

        { showBottomPagination && !mapIsOpen && showPagination &&
          <div className="container bottom-pagination">
            { pagination }
          </div>
        }
      </div>

      { (mapIsOpen || mapLoaded) &&
        <div className={mapClass}>
          <Suspense fallback={
            <div className={`skeleton-box-wrapper`}>
              <div className={`skeleton-box`} />
            </div>
          }>
            <MapSection />
          </Suspense>
        </div>
      }

    </div>
  )
};


export default LocationsContent;
