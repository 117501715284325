import React, { Suspense, useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import Select from "react-select";
import Switch from "react-switch";
import { isMobileOnly } from 'react-device-detect';
import 'react-dropdown/style.css';

import { SvgIco } from '../../SharedComponents/Icons';
import { Context } from "../index";

import './index.sass';


const SortBar = () => {

  const {
    changePerPage,
    keywords,
    listViewAsList,
    mapOptions,
    onlyInternalLocations,
    requestParams,
    section,
    setListViewAsList,
    // siteKey,
    sortLocationsBy,
    tawkIsLoaded,
    toggleMap,
  } = useContext(Context);

  const { kinds: selectedKinds, per_page: perPage, sort_by: sortBy } = requestParams;
  const { countryAlpha2, frontendLeasePrices, } = keywords;

  // const disableMap = onlyInternalLocations && (siteKey === 'dk' || countryAlpha2 === 'dk');
  const listViewSwitcherClasses = classNames("sort-bar__layout-switcher-pill", { "active": listViewAsList });
  const gridViewSwitcherClasses = classNames("sort-bar__layout-switcher-pill", { "active": !listViewAsList });
  const mapIsOpen = mapOptions.open;

  const quantityOptions = [
    {value: "12", label:`12 ${ I18n.t('lb_showroom.showroom.list.per_page') }`},
    {value: "30", label:`30 ${ I18n.t('lb_showroom.showroom.list.per_page') }`},
    {value: "60", label:`60 ${ I18n.t('lb_showroom.showroom.list.per_page') }`},
    {value: "90", label:`90 ${ I18n.t('lb_showroom.showroom.list.per_page') }`},
  ];

  const sortTypeOptions = [
    {value: 'created_at|desc', label: I18n.t('generic.date')},
    {value: 'area_from|asc', label: `${I18n.t('generic.area')}, ${I18n.t('generic.low_to_high')}`},
    {value: 'area_from|desc', label: `${I18n.t('generic.area')}, ${I18n.t('generic.high_to_low')}`},
    {value: 'address_line_1|asc', label: `${I18n.t('generic.address')} ${I18n.t('generic.alphabet_order')}`},
    {value: 'address_line_1|desc', label: `${I18n.t('generic.address')} ${I18n.t('generic.alphabet_order_reverse')}`},
    {value: 'virtual_tour|desc', label:I18n.t('locations.scores.virtual_tour')},
    {value: 'energy_rating|asc', label: `${I18n.t('activerecord.attributes.property.energy_rating')}, A -> G`},
    {value: 'energy_rating|desc', label: `${I18n.t('activerecord.attributes.property.energy_rating')}, G -> A`},
    {value: 'score|desc', label:I18n.t('locations.sort_by.score'), className: !selectedSortType ? ' is-selected' : '' },
  ];

  const sortingPriceFieldName = section === 'lease' ? 'yearly_rent_per_m2_amount_from' : 'sale_price';

  frontendLeasePrices && sortTypeOptions.splice(3, 0,
    {value: sortingPriceFieldName + '|asc', label: `${I18n.t('generic.price')}, ${I18n.t('generic.low_to_high')}`},
    {value: sortingPriceFieldName + '|desc', label: `${I18n.t('generic.price')}, ${I18n.t('generic.high_to_low')}`},
  );

  const [selectedSortType, setSelectedSortType] = useState(sortBy ? sortTypeOptions.filter(option => sortBy === option.value)[0] : sortTypeOptions[0]);
  const [selectedPerPage, setSelectedPerPage] = useState(perPage ? quantityOptions.filter(option => option.value === perPage)[0] : quantityOptions[0]);


  useEffect(() => {
    // need to rewrite and remove react-select
    const perPageLabel = document.createElement("P");
    const perPageLabelContent = document.createTextNode(`${I18n.t('views.locations.show.per_page_label')}:`);
    const sortByLabel = document.createElement("P");
    const sortByLabelContent = document.createTextNode(`${I18n.t('lb_showroom.showroom.list.sort_by')}:`);

    perPageLabel.appendChild(perPageLabelContent);
    document.querySelector('.per-page__control').insertBefore(perPageLabel, document.querySelector('.per-page__control').childNodes[0]);

    sortByLabel.appendChild(sortByLabelContent);
    document.querySelector('.sort-by__control').insertBefore(sortByLabel, document.querySelector('.sort-by__control').childNodes[0]);
  }, []);


  useEffect(() => {
    sortBy !== selectedSortType.value && setSelectedSortType(sortBy ? sortTypeOptions.filter(option => sortBy === option.value)[0] : sortTypeOptions[0]);
  }, [sortBy]);

  useEffect(() => {
    perPage !== selectedSortType.value && setSelectedPerPage(perPage ? quantityOptions.filter(option => option.value === perPage)[0] : quantityOptions[0]);
  }, [perPage]);


  useEffect(() => {
    // remove-tawk-chat-when-open-map
    if (tawkIsLoaded) {
      // In case there's going to be a problem with API or internet
      // to avoid main thread overwork
      let counter = 200;

      (function toggleTawk() {
        try {
          if (Tawk_API.onLoaded) {
            mapIsOpen ? Tawk_API.hideWidget() : Tawk_API.showWidget();
          } else {
            if (counter !== 0) {
              counter = --counter;
              setTimeout(toggleTawk, 100);
            }
          }
        } catch (error) {
          if (counter !== 0) {
            counter = --counter;
            setTimeout(toggleTawk, 100);
          }
        }
      })();
    }
  }, [tawkIsLoaded]);


  const handleListView = () => {
    setListViewAsList(true);
    localStorage && localStorage.setItem('viewedAsList', 'true');
  };


  const handleGridView = () => {
    setListViewAsList(false);
    localStorage && localStorage.setItem('viewedAsList', 'false');
  };


  const changeSortType = (e) => {
    setSelectedSortType(sortTypeOptions.filter(option => e.value === option.value)[0]);
    sortLocationsBy(e.value);
  };


  const handleChangePerPage = (e) => {
    setSelectedPerPage(quantityOptions.filter(option => option.value === e.value)[0]);
    changePerPage(e.value);
  };


  const selectedSortTypeOption = () => {
    // To prevent page fall in case sort decrease change in url string
    const strictSelectedOption = sortTypeOptions.filter(option => selectedSortType === option.value);
    const selectedSortTypeValue = selectedSortType && selectedSortType.value;

    if (strictSelectedOption.length) {
      return strictSelectedOption[0];
    } else {
      const optionalSelect = sortTypeOptions.filter(option => option.value.includes(selectedSortTypeValue.slice(0, selectedSortTypeValue.indexOf('|'))));
      return optionalSelect.length ? optionalSelect[0] : null;
    }
  };


  const defaultSortType = (selectedSortType && selectedSortTypeOption()) || sortTypeOptions[sortTypeOptions.length - 1];
  const defaultQuantity = perPage ? quantityOptions.filter(option => option.value === perPage)[0] : quantityOptions[0];


  return (
    <div className="sort-bar sort-bar-container">
      <div className="container max-width">
        <div className="sort-bar__main-wrapper">
          { !isMobileOnly && !mapIsOpen &&
            <div className="sort-bar__left-part">
              <div className="sort-bar__layout-switcher">
                <div className={gridViewSwitcherClasses} onClick={handleGridView}>
                  <SvgIco name="window" size={20} />
                </div>
                <div className={listViewSwitcherClasses} onClick={handleListView}>
                  <SvgIco name="format-list-bulleted" size={20} />
                </div>
              </div>
            </div>
          }

          <div className="sort-bar__right-part">

            <div className="sort-bar__dropdown-wrapper">
              <div className="sort-bar__sorting-wrapper">
                <div className="sort-bar__sort-type-wrapper">
                  <SvgIco name="swap" size={24} className="sort-bar__sort-type-icon" />
                  <Select
                    className="sort-by"
                    classNamePrefix="sort-by"
                    defaultValue={defaultSortType}
                    isSearchable={false}
                    onChange={changeSortType}
                    options={sortTypeOptions}
                    value={selectedSortType}
                  />
                </div>

                <div className="sort-bar__sorting-wrapper-divider" />

                <div className="sort-bar__per-page-wrapper sorted-quantity">
                  <Select
                    className="per-page"
                    classNamePrefix="per-page"
                    defaultValue={defaultQuantity}
                    isSearchable={false}
                    onChange={handleChangePerPage}
                    options={quantityOptions}
                    value={selectedPerPage}
                  />
                </div>
              </div>
            </div>

            { ((onlyInternalLocations && mapIsOpen) || !onlyInternalLocations) &&
              <div className={`sort-bar__map-toggle ${mapIsOpen ? 'on' : ''}`}>
                <label>
                  <Switch
                    checked={mapIsOpen}
                    checkedIcon={false}
                    className="sort-bar__map-toggle-switch"
                    handleDiameter={6}
                    height={12}
                    offHandleColor="#FFFFFF"
                    onChange={() => toggleMap(false)}
                    onColor="#FFFFFF"
                    offColor="#626262"
                    onHandleColor="#8ACB36"
                    width={22}
                    uncheckedIcon={false}
                  />

                  <div className="sort-bar__map-toggle-label">
                    { I18n.t('tooltips.showroom.view_on_map') }
                  </div>
                </label>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};


export default SortBar;
