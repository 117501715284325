export const checkOrderedLocation = () => {
  let todayDate = new Date();
  let oneWeekAgo = new Date(todayDate.setDate(todayDate.getDate() -7));

  let allOrderedObjects = JSON.parse(localStorage.getItem('my.ordered_locations'));
  const orderedKeys = Object.keys(allOrderedObjects);

  const orderedLocationsAll = orderedKeys.map(elem => {
    let orderedDate = new Date(allOrderedObjects[`${elem}`][0].timestamp);
    if (oneWeekAgo < orderedDate) {
      return elem
    }
  });
  const orderedLocations = orderedLocationsAll.filter(elem => elem !== undefined);

  return orderedLocations;
};