// import ES6Promise from 'es6-promise';
import { apiProvider } from './core';
import { camelizeName } from '../utils/helpers';
// ES6Promise.polyfill();


export const requestBuilder = (requestParams) => {
  const bodyFormData = new FormData();

  Object.keys(requestParams).map(elem => {
    if (Array.isArray(requestParams[elem]))  {
      requestParams[elem].map(section => bodyFormData.append(`${elem}[]`, section));
    } else {
      if (requestParams[elem] != null) {
        bodyFormData.append(elem, requestParams[elem]);
      }
    }
  });

  return bodyFormData;
};


class httpService {

  static getLocationsByIds(locationsIds) {
    const locale = localStorage.getItem('locale');
    const url = '/api/locations/get_by_ids?locale=' + locale + '&';
    const params = locationsIds.reduce((sum, item) => sum + 'ids[]=' + item + '&', '');
    const queryString = url + params.slice(0, -1);

    return apiProvider.getAll(queryString);
  }


  static orderInfoListViewForm(url, data, uuid) {

    const {
      name,
      email,
      company: company_name,
      option_parking_lots,
      phone: phone_number,
      // jeudan_policy,
      comment,
      ab_code
    } = data;

    const order = {
      order: {
        location_uuid: uuid,
        person_name: camelizeName(name),
        company_name,
        phone_number,
        email,
        option_parking_lots,
        // jeudan_policy,
        comment,
        ab_code,
      },
      api_version: 2
    };

    return apiProvider.post(url, order);
  }
}

export default httpService;
