import React, { useContext, useEffect, useRef, useState, Fragment, Suspense } from 'react';
import "scroll-behavior-polyfill";
import PropTypes from 'prop-types';
import queryString from 'query-string';
import update from 'immutability-helper';
import classNames from 'classnames';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { debounce } from "throttle-debounce";

import { queryParseHelper } from '../../../utils/queryParseHelper';
import { SvgIco } from '../../SharedComponents/Icons';
import { getObjectIndexInArray } from '../../helpers/array';
import { locationsCountByKinds } from '../../helpers/coundByKindLocation';
import LocationSearch from '../../LocationSearch/index';
import AreaFilter from '../AreaFilter';
import StickyFilterBar from './StickyFilterBar';
import { Context } from "../index";

const DropDownContainer = React.lazy(() => import('../../SharedComponents/DropDownContainer'));
const DropdownSelector = React.lazy(() => import('../DropdownSelector'));
const EnergyRatingFilter = React.lazy(() => import('../EnergyRatingFilter'));
const FacilitiesFilter = React.lazy(() => import('../FacilitiesFilter'));
const NoLinearRangeSlider = React.lazy(() => import('../../SharedComponents/NonLinearRangeSelector'));
const RangeSelector = React.lazy(() => import('../../SharedComponents/RangeSelector'));
const SpaceCalculator = React.lazy(() => import('../../SharedComponents/SpaceCalculator'));
const SuitableForFilter = React.lazy(() => import('../SuitableForFilter'));

import './index.sass';


function yieldToMain () {
  return new Promise(resolve => {
    setTimeout(resolve, 0);
  });
}


const MainFilter = () => {

  const debounceKinds = debounce(600, (func) => {
    func();
  });

  const {
    areaId,
    areaType,
    filterAreas,
    filterAreaList,
    filterLocationsByPrice,
    filterLocationsBySquare,
    getInitialListLocationByDistricts,
    getEnergyRatings,
    getKinds,
    getFacilitiesList,
    getListLocationByDistrictsArea,
    getListLocationMobileFilters,
    getSuitableForKinds,
    groupedPostalDistrictsIds,
    initialPostalDistrictsIds,
    keywords,
    kindFilterToggle,
    locationKinds,
    mapOptions,
    onlyInternalLocations,
    requestParams,
    selectedEnergyRatings,
    selectedFacilities,
    selectedSuitableFor,
    setRequestParams,
    setSelectedEnergyRatings,
    setSelectedFacilities,
    setSelectedSuitableFor,
    siteKey,
    subSite,
    suitableForStructuredTypes,
    toggleMap,
    windowWidth,
  } = useContext(Context);

  const {
    area_max,
    area_min,
    energy_rating,
    facilities,
    kinds,
    rent_max,
    rent_min,
    suitable_for,
  } = requestParams;

  const {
    apiAutocompletePath,
    countryAlpha2,
    defaultKinds,
    frontendLeasePrices,
    initialPostalDistrictIds,
    maxArea,
    newSearchAgentPath,
    provinceId,
    saleMaxPrice,
    sections,
    slidersDefaultCurrencyFormatted,
    yearlyPerM2MaxPrice,
  } = keywords;

  const [clearFacilities, setClearFacilities] = useState(false);
  const [clearSuitableFor, setClearSuitableFor] = useState(false);
  const [filtersMobileScroll, setFiltersMobileScroll] = useState(0);
  const [filtersModalIsOpen, setFiltersModalIsOpen] = useState(false);
  const [mobileFiltersOn, setMobileFiltersOn] = useState(false);
  const [parsedData, setParsedData] = useState([]);
  const [prevSelectedFacilities, setPrevSelectedFacilities] = useState(null);
  const [prevSelectedFilters, setPrevSelectedFilters] = useState({});
  const [selectedAreaIds, setSelectedAreaIds] = useState({});
  const [selectedMeters, setSelectedMeters] = useState({ max: Number(area_max) || 999999, min: Number(area_min) || 0 });
  const [selectedPrice, setSelectedPrice] = useState({ max: Number(rent_max) || 1000, min: Number(rent_min) || 0 });
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [showApplyFiltersButton, setShowApplyFiltersButton] = useState(false);
  const [showClearFiltersButton, setShowClearFiltersButton] = useState(false);

  const filterWrapper = useRef();


  useEffect(() => {
    // An approach to avoid long tasks
    const tasks = [
      () => getKinds(requestParams),
      () => getEnergyRatings(requestParams),
      () => filterAreas(),
      () => getSuitableForKinds(requestParams),
      () => getFacilitiesList(requestParams),
    ];

    (async function saveSettings () {
      while (tasks.length > 0) {
        // Shift the first task off the tasks array:
        const task = tasks.shift();

        // Run the task:
        task();

        // Yield to the main thread:
        await yieldToMain();
      }
    })();
  }, []);


  useEffect(() => {
    window.onpopstate = () => onPopStateEvent();
    window.addEventListener("orientationchange", handleMobilesFiltersOn);

    return () => {
      window.removeEventListener("orientationchange", handleMobilesFiltersOn);
    };
  }, []);


  useEffect(() => {
    (Number(rent_max) !== Number(selectedPrice.max) || Number(rent_min) !== Number(selectedPrice.min))
    && setSelectedPrice({ max: Number(rent_max), min: Number(rent_min) });
  }, [rent_max, rent_min]);


  useEffect(() => {
    (Number(area_max) !== Number(selectedMeters.max) || Number(area_min) !== Number(selectedMeters.min))
    && setSelectedMeters({ max: Number(area_max), min: Number(area_min) });
  }, [area_max, area_min]);


  useEffect(() => {
    setSelectedTypes(kinds
      ? kinds.map(elem => {
        const translatedKind = I18n.t(`global.models.location.kinds.${elem}.normal`);
        const name = translatedKind['one'] ? translatedKind['one'] : translatedKind;
        return { id: elem, name };
      })
      : []);
  }, [kinds]);


  useEffect(() => {
    // We need to save default data for mobile menu in case we shut down the menu without applying filters,
    // and to have an ability to compare states in order to show/not show Apply Filters Button
    if (mobileFiltersOn) {
      setPrevSelectedFilters({
        selectedTypes: JSON.parse(JSON.stringify(selectedTypes)),
        selectedAreaIds: JSON.parse(JSON.stringify(selectedAreaIds)),
        selectedPrice: JSON.parse(JSON.stringify(selectedPrice)),
        selectedMeters: JSON.parse(JSON.stringify(selectedMeters)),
        selectedEnergyRatings,
      });
      setPrevSelectedFacilities({ selectedSuitableFor, selectedFacilities });
      handleClearFiltersButton();
    }
  }, [mobileFiltersOn]);


  useEffect(() => {
    if (mobileFiltersOn) {
      handleClearFiltersButton();
      handleApplyFiltersButton();
    }
  }, [requestParams]);


  useEffect(() => {
    const newData = Object.values(filterAreaList);

    const filterEmptyPostalDistrict = newData.reduce((elem, currentValue) => {
      const filterByTotalSum = currentValue.postal_districts.filter(area => (area.total_sum > 0));
      const updated = {...currentValue, postal_districts: filterByTotalSum};
      elem.push(updated);
      return elem;
    }, []);

    const parsedData = filterEmptyPostalDistrict.map(elem => {
      const getTotalSum = elem.postal_districts.reduce((accum, currentValue) => {
        return accum + currentValue.total_sum
      }, 0);
      let added = { ...elem, count: getTotalSum };
      const { postal_districts: areas, ...rest } = added;
      return { areas, ...rest }
    });

    setParsedData(parsedData);

    if (!provinceId) {
      const selectedAllIds = parsedData.reduce((accum, currentValue) => {
        accum[currentValue.id] = currentValue.areas.map(elem => elem.id)
        return accum
      }, {});

      setSelectedAreaIds(selectedAllIds);
    } else {

      let newSelectedIds = {};

      parsedData.map(item => {
        const selectedIds = [];

        item.areas.map(area => initialPostalDistrictsIds.indexOf(area.id) >= 0 && selectedIds.push(area.id));

        if (selectedIds.length) newSelectedIds[item.id] = selectedIds;
      });

      setSelectedAreaIds(newSelectedIds);
    }
  }, [filterAreaList, initialPostalDistrictsIds]);


  const onPopStateEvent = () => {
    const getPath = queryString.parse(window.location.search);
    const getPathBack = history.state ? queryString.parse(history.state.query) : {};
    let newRequestParams = { ...requestParams };


    let defaultParams = {
      area_max: maxArea,
      area_min: 0,
      energy_rating: [],
      kinds: defaultKinds,
      page: 1,
      postal_districts: initialPostalDistrictIds,
      rent_max: sections.includes('lease') ? yearlyPerM2MaxPrice : saleMaxPrice,
      rent_min: 0,
      sections: sections,
    };

    newRequestParams.postal_districts = Object.keys(initialPostalDistrictIds).reduce((acc, province) => acc.concat(initialPostalDistrictIds[province]), []);
    delete newRequestParams.suitable_for;
    delete newRequestParams.facilities;
    defaultParams = queryParseHelper(defaultParams, getPath)

    if (!Object.keys(getPath).length > 0) {
      newRequestParams.postal_districts = areaType === 'Province'
        ? groupedPostalDistrictsIds[areaId]
        : areaType === "PostalDistrict"
        ? [areaId]
        : parsedData.reduce((acc, province) => acc.concat(province.areas.map(district => district.id)), []);

      delete newRequestParams.suitable_for;
      delete newRequestParams.facilities;
    }

    newRequestParams = queryParseHelper(newRequestParams, getPathBack, true, defaultParams);
    getInitialListLocationByDistricts(newRequestParams, getPathBack.search_id, true);
    setRequestParams({ ...newRequestParams });

    const selectedTypes = newRequestParams.kinds
      ? mapSelectedKinds(newRequestParams.kinds.includes('cafe') ? ['store']: newRequestParams.kinds)
      : [];

    setSelectedTypes(selectedTypes);
    setSelectedPrice(prevData =>({ ...prevData, max: Number(newRequestParams.rent_max), min: Number(newRequestParams.rent_min) }));
    setSelectedMeters(prevData =>({ ...prevData, max: Number(newRequestParams.area_max), min: Number(newRequestParams.area_min) }));
    setSelectedEnergyRatings([ ...newRequestParams.energy_rating ]);
  };


  const mapSelectedKinds = (selectedKinds) => {
    return selectedKinds.map(elem => {
      const translatedKind = I18n.t(`global.models.location.kinds.${elem}.normal`);
      const name = translatedKind['one'] ? translatedKind['one'] : translatedKind;

      return { id: elem, name };
    });
  };


  const filterLocationByPrice = (price, shouldSubmit) => {
    const currentPrice = price || selectedPrice;

    price && setSelectedPrice(price);

    if (shouldSubmit) {
      // Google Analytics
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'ga4-price-filter-change',
        eventCategory: 'price-filter-change-list-view',
        eventAction: 'price-filter-change-list-view',
      });

      mobileFiltersOn
        ? setRequestParams(prevParams => ({ ...prevParams, rent_min: currentPrice.min, rent_max: currentPrice.max }))
        : filterLocationsByPrice(currentPrice);
    };
  };


  const filterLocationBySquare = (square, shouldSubmit) => {
    const currentSquare = square || selectedMeters;

    square && setSelectedMeters(square);

    if (shouldSubmit) {
      mobileFiltersOn
        ? setRequestParams(prevParams => ({ ...prevParams, area_min: currentSquare.min, area_max: currentSquare.max, }))
        : filterLocationsBySquare(currentSquare);
    }
  };


  const filterLocationByArea = (updatedAreas) => {
    const newSelectedAreasIds = [];

    setSelectedAreaIds(updatedAreas);
    Object.keys(updatedAreas).map(elem => updatedAreas[elem].map(item => newSelectedAreasIds.push(item)));
    mobileFiltersOn
      ? setRequestParams(prevParams => ({ ...prevParams, postal_districts: newSelectedAreasIds }))
      : getListLocationByDistrictsArea(newSelectedAreasIds);
  };


  const selectType = (e, item) => {
    const index = getObjectIndexInArray(selectedTypes, 'id', item.id);
    const typeIsSelected = (index >= 0);
    const newRequestParams = { ...requestParams };
    const newSelectedTypes = typeIsSelected ? update(selectedTypes, {$splice: [[index, 1]] }) : update(selectedTypes, {$push: [item]});

    setSelectedTypes(newSelectedTypes);

    const filterKinds = () => {
      const suitableForTypesAreSelected = newSelectedTypes.some(type =>
        type.id === 'office' || type.id === 'warehouse' || type.id === 'coworking' || type.id === 'store' || type.id === 'business_center'
      );

      if (newSelectedTypes && newSelectedTypes.length && requestParams.suitable_for && suitableForTypesAreSelected) {
        let newSuitableForList = [];
        let sortedSuitableForByKinds = [];

        newSelectedTypes
          .map(type =>
            suitableForStructuredTypes[type.id] && suitableForStructuredTypes[type.id].map(suitableForKind =>
              !sortedSuitableForByKinds.includes(suitableForKind) && sortedSuitableForByKinds.push(suitableForKind))
          );

        newSuitableForList = requestParams.suitable_for.filter(elem => sortedSuitableForByKinds.includes(elem));
        setRequestParams(prevParams => ({ ...prevParams, suitable_for: newSuitableForList }));
        newRequestParams.suitable_for = newSuitableForList;
        setSelectedSuitableFor(newSuitableForList);
      };


      // In case last of the suitable_for type is deleted while there are others selected
      if (!suitableForTypesAreSelected && requestParams.suitable_for && requestParams.suitable_for.length && newSelectedTypes.length > 0) {
        setRequestParams(prevParams => ({ ...prevParams, suitable_for: [] }));
        delete newRequestParams.suitable_for;
        setSelectedSuitableFor([]);
      }

      !mobileFiltersOn && kindFilterToggle(newSelectedTypes, newRequestParams);
      setRequestParams(prevParams => ({ ...prevParams, kinds: newSelectedTypes.map(elem => elem.id) }));
      newRequestParams.kinds = newSelectedTypes.map(elem => elem.id);
    };

    // Debounce kinds filtering on desktop to prevent unnecessary requests and rendering in case of multiple kinds selection
    mobileFiltersOn ? filterKinds() : debounceKinds(() => filterKinds());
  };


  const toggleAreaSelect = (parentID, areaID) => {
    let selectedAreas = { ...selectedAreaIds };
    let selectedProvince = selectedAreas[parentID];

    selectedProvince
    ? (selectedProvince.indexOf(areaID) >= 0 ? selectedProvince.splice(selectedProvince.indexOf(areaID), 1) : selectedProvince.push(areaID))
    : selectedAreas[parentID] = [areaID];

    selectedAreas[parentID].length === 0 && delete selectedAreas[parentID];
    filterLocationByArea(selectedAreas);
  };


  const selectAll = (province, parsedData) => {
    let selectedAreas = { ...selectedAreaIds };

    parsedData.forEach(section => {
      if(section.id === province.id) {
        selectedAreas[province.id] = section.areas.map(area => area.id);
      }
    });

    filterLocationByArea(selectedAreas);
  };


  const deselectAll = (parentID) => {
    let selectedAreas = { ...selectedAreaIds };

    delete selectedAreas[parentID];
    filterLocationByArea(selectedAreas);
  };


  const handleMobilesFiltersOn = () => {
    setTimeout(() => {
      if (window.innerWidth > 992 && mobileFiltersOn) {
        toggleMobileFilters(false);
      }
    }, 1);
  };


  const handleMobilesFiltersSubmit = () => {
    setMobileFiltersOn(false);
    setShowApplyFiltersButton(false);
    document.getElementById('top-menu').classList.remove('hidden');
    enableBodyScroll(document.getElementById('filtersWrapper'));
    getListLocationMobileFilters();
    setSelectedFacilities(facilities || []);
    setSelectedSuitableFor(suitable_for || [])
    window.scroll({ top: 0, behavior: 'smooth' });
  };


  const toggleMobileFilters = (open) => {
    // we take this element before user close mobile filter because after react don't render it and it will be null
    const removeScrollListenerElement = document.getElementById('filtersWrapper');
    const filtersWrapper = document.getElementById('filtersWrapper');
    const mobileFiltersOnState = open;

    setMobileFiltersOn(mobileFiltersOnState);

    if (mobileFiltersOnState) {
      document.getElementById('top-menu').classList.add('hidden');
      disableBodyScroll(filtersWrapper);

      // Google Analytics
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'ga4-filters-toggle',
        eventCategory: 'filters-toggle-list-view',
        eventAction: 'filters-toggle-list-view',
      });
    } else {
      setShowApplyFiltersButton(false);

      const selectedAreasIds = [];

      prevSelectedFilters.selectedAreaIds && Object.keys(prevSelectedFilters.selectedAreaIds)
        .map(elem => prevSelectedFilters.selectedAreaIds[elem].map(item => selectedAreasIds.push(item)));

      const prevParams = {
        ...requestParams,
        sections: keywords.sections,
        rent_min: prevSelectedFilters.selectedPrice.min,
        rent_max: prevSelectedFilters.selectedPrice.max,
        area_min: prevSelectedFilters.selectedMeters.min,
        area_max: prevSelectedFilters.selectedMeters.max,
        postal_districts: selectedAreasIds,
        kinds: prevSelectedFilters.selectedTypes.map(type => type.id),
        suitable_for: prevSelectedFacilities.selectedSuitableFor,
        facilities: prevSelectedFacilities.selectedFacilities,
        energy_rating: prevSelectedFilters.selectedEnergyRatings,
      };

      setRequestParams(prevState => ({ ...prevState, ...prevParams }));
      setSelectedFacilities(facilities || []);
      setSelectedSuitableFor(suitable_for || []);
      document.getElementById('top-menu').classList.remove('hidden');
      enableBodyScroll(removeScrollListenerElement);
    }
  };


  const handleClearFilters = () => {
    const defaultParams = {
      ...requestParams,
      sections: sections,
      rent_min: 0,
      rent_max: sections.includes('lease') ? yearlyPerM2MaxPrice : saleMaxPrice,
      area_min: 0,
      area_max: maxArea,
      postal_districts: [],
      kinds: [],
      suitable_for: [],
      facilities: [],
      energy_rating: [],
    };

    setSelectedMeters({ min: 0, max: maxArea });
    setSelectedPrice({ min: 0, max: sections.includes('lease') ? yearlyPerM2MaxPrice : saleMaxPrice });
    setSelectedTypes([]);
    setSelectedAreaIds({});
    setRequestParams(prevParams => ({ ...prevParams, ...defaultParams }));
    setClearFacilities(prevState => !prevState);
    setClearSuitableFor(prevState => !prevState);
  };


  // For Clear Filters button, show if any filter is selected
  const handleClearFiltersButton = () => {
    const showClearFiltersButton = !!(
         selectedTypes.length
      || energy_rating.length
      || Object.keys(selectedAreaIds).length
      || selectedPrice.min > 0
      || selectedPrice.max !== (sections.includes('lease') ? yearlyPerM2MaxPrice : saleMaxPrice)
      || selectedMeters.min > 0
      || selectedMeters.max !== maxArea
      || (suitable_for && suitable_for.length)
      || (facilities && facilities.length));

    setShowClearFiltersButton(showClearFiltersButton);
  };


  // For apply filters button, show if there is any difference between before-opening chosen filters and current
  const handleApplyFiltersButton = () => {
    const selectedAreas = [];
    const prevSelectedAreas = [];
    const suitableFor = suitable_for || [];
    const currentFacilities = facilities || [];
    const selectedEnergyRatings = energy_rating;

    Object.keys(selectedAreaIds).map(elem => selectedAreaIds[elem].map(item => selectedAreas.push(item)));
    prevSelectedFilters.selectedAreaIds && Object.keys(prevSelectedFilters.selectedAreaIds).map(elem => prevSelectedFilters.selectedAreaIds[elem].map(item => prevSelectedAreas.push(item)));

    const showApplyFiltersButton = !(
         selectedTypes.length === prevSelectedFilters.selectedTypes.length
      && selectedTypes.every(type => prevSelectedFilters.selectedTypes.find(prevType => prevType.id === type.id))
      && selectedAreas.length === prevSelectedAreas.length
      && selectedAreas.every(area => prevSelectedAreas.indexOf(area) >= 0)
      && selectedPrice.min === prevSelectedFilters.selectedPrice.min
      && selectedPrice.max === prevSelectedFilters.selectedPrice.max
      && selectedMeters.min === prevSelectedFilters.selectedMeters.min
      && selectedMeters.max === prevSelectedFilters.selectedMeters.max
      && suitableFor.length === prevSelectedFacilities.selectedSuitableFor.length
      && suitableFor.every(suitableFor => prevSelectedFacilities.selectedSuitableFor.indexOf(suitableFor) >= 0)
      && currentFacilities.length === prevSelectedFacilities.selectedFacilities.length
      && currentFacilities.every(facility => prevSelectedFacilities.selectedFacilities.indexOf(facility) >= 0)
      && selectedEnergyRatings.length === prevSelectedFilters.selectedEnergyRatings.length
      && selectedEnergyRatings.every(rating => prevSelectedFilters.selectedEnergyRatings.indexOf(rating) >= 0)
    );

    setShowApplyFiltersButton(showApplyFiltersButton);
  };


  const openFiltersModal = () => {
    setFiltersModalIsOpen(true);
    window.addEventListener('click', handleClickOutside);
    document.body.classList.toggle('modal-open');

    // Google Analytics
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'ga4-filters-toggle',
      eventCategory: 'filters-toggle-list-view',
      eventAction: 'filters-toggle-list-view',
    });
  };


  const closeFiltersModal = () => {
    setFiltersModalIsOpen(false);
    window.removeEventListener('click', handleClickOutside);
    document.body.classList.toggle('modal-open');
  };


  const handleClickOutside = (e) => {
    if (filterWrapper && filterWrapper.current) {
      const target = e.target;
      const isModal = filterWrapper.current.contains(target);
      const filterToggle = document.getElementById('filterToggle');
      const isFilterButton = filterToggle === target || filterToggle.contains(target);

      !isModal && !isFilterButton && closeFiltersModal();
    }
  };


  const setSearchAgentUrl = () => {
    const getPath = queryString.parse(window.location.search);
    const { area_max, area_min, rent_max, rent_min, search_id } = getPath;
    const currentKinds = mobileFiltersOn ? (prevSelectedFilters && prevSelectedFilters.selectedTypes && prevSelectedFilters.selectedTypes) || null : selectedTypes;

    const searchAgentDefaultParams = {
      area_max,
      area_min,
      rent_max,
      rent_min,
      ['kinds[]']: (currentKinds && Object.keys(currentKinds).length && currentKinds.map(type => type.id)) || null ,
    };

    search_id && (searchAgentDefaultParams.search_id = search_id);
    subSite === 'sales' && sections.length < 3 && (searchAgentDefaultParams['sections[]'] = sections);

    const searchAgentParams = Object.fromEntries(Object.entries(searchAgentDefaultParams).filter(([key, value]) => !!value));
    const searchAgentQueryString = new URLSearchParams(searchAgentParams).toString();

    return searchAgentQueryString ? `${subSite === 'sales' ? '&' : '?'}${searchAgentQueryString}` : '';
  };


  const handleMobileScroll = () => {
    const modalFiltersWrapper = document.getElementById('mobile-filters-scroll-target');
    const rect = modalFiltersWrapper && modalFiltersWrapper.getBoundingClientRect();
    const wrapperTop = rect && rect.top;

    rect && setFiltersMobileScroll(wrapperTop);
  };


  // const disableMap = onlyInternalLocations && (siteKey === 'dk' || countryAlpha2 === 'dk');
  const mapIsOpen = mapOptions.open;
  const maxPrice = sections[0] === 'lease' ? yearlyPerM2MaxPrice : saleMaxPrice;
  const mainFilterClasses = classNames('main-filter', { 'main-filter--top': mapIsOpen });
  const filtersWrapperClasses = classNames('lv-filter__filters-wrapper', { 'mobile-active': mobileFiltersOn }, { 'with-button': showApplyFiltersButton });
  const isUsa = countryAlpha2 === 'us';
  const areaFilterIcon = isUsa ? 'area_ft2' : 'area_m2';
  const areaUnit = isUsa ? 'ft²' : 'm²';
  const areaFilterTitle = isUsa ? I18n.t('apps.list_view.filter.area.title.ft2') : I18n.t('apps.list_view.filter.area.title.m2');
  const areaFilterText = isUsa ? I18n.t('apps.list_view.filter.area.text.ft2') : I18n.t('apps.list_view.filter.area.text.m2');
  const isMobileMenu = windowWidth < 992;
  const selectedPriceTooltip = frontendLeasePrices && (selectedPrice.min > 0 || selectedPrice.max !== maxPrice) && `${selectedPrice.min} - ${selectedPrice.max} ${slidersDefaultCurrencyFormatted}`;
  const selectedSizeTooltip = (selectedMeters.min > 0 || selectedMeters.max !== maxArea)
    && `${selectedMeters.min} - ${selectedMeters.max} m², ${Math.round(selectedMeters.min / 15)} - ${Math.round(selectedMeters.max / 15)} ${I18n.t('search_agent.configuration.employees.selector_currency')}`;

  let duplicateParsedData = JSON.parse(JSON.stringify(parsedData));
  let selectedKinds = requestParams.kinds;
  const hideSquareFilter = selectedKinds && selectedKinds.length === 1 && selectedKinds[0] === 'parking';
  const showOfficeSpaceCalculator = siteKey === 'dk' && (selectedKinds.length === 0 || selectedKinds.includes('office'));

  selectedKinds === undefined || selectedKinds.length === 0
    ? parsedData.map(elem => elem.count = elem.areas.reduce((accum, currentValue) => accum+currentValue.total_sum, 0))
    : duplicateParsedData.map(elem => {
        elem.areas.map(elem => elem.total_sum = locationsCountByKinds(selectedKinds, elem.sums));
        elem.count = elem.areas.reduce((accum, currentValue) => accum + currentValue.total_sum, 0);
        return elem;
    });


  return (
    <div className={mainFilterClasses} id="main-filter">
      <StickyFilterBar mapOptions={mapOptions} mobileFiltersOn={mobileFiltersOn}>
        <div className={`lv-filter__container ${mapIsOpen ? 'map' : ''}`}>
          <div className="container max-width">
            <div className="row lv-filter__row">
              <div className="col-sm-12">
                <div className="lv-filter-wrapper">

                  <div className="lv-filter lv-filter__location-search">
                    <LocationSearch
                      apiAutocompletePath={apiAutocompletePath}
                      placeholder={I18n.t('entry_pages.main_search.input_placeholder')}
                      selectedTypes={selectedTypes}
                      showFixedSearchIcon={true}
                      siteKey={siteKey}
                    />
                  </div>

                  <div className="lv-filter__right-part">
                    <div className="lv-filter lv-filter__area desktop">
                      <AreaFilter
                        deselectAll={deselectAll}
                        dynamicData={duplicateParsedData}
                        isMobileMenu={false}
                        placeholder={I18n.t('apps.lb_showroom.filters.choose_areas')}
                        selectedData={selectedAreaIds}
                        selectAction={toggleAreaSelect}
                        selectAll={(e) => selectAll(e, duplicateParsedData)}
                      />
                    </div>

                    <div className="lv-filter__filters-toggle-wrapper">
                      <div className={`lv-filter__filters-toggle`} id="filterToggle" onClick={isMobileMenu ? () => toggleMobileFilters(true) : openFiltersModal}>
                        <div className="lv-filter__mobile-toggle-left">
                          <SvgIco name="tune" size={20} />
                          <p>{ I18n.t('properties.filter.tab_toggle_button') }</p>
                        </div>
                      </div>

                      { showOfficeSpaceCalculator &&
                        <Suspense fallback={''}>
                          <SpaceCalculator />
                        </Suspense>
                      }
                    </div>

                    { ((onlyInternalLocations && mapIsOpen) || !onlyInternalLocations) &&
                      <div className="sort-bar__mobile-map-button" onClick={toggleMap}>
                        { mapIsOpen
                          ? <SvgIco name="format-list-bulleted" size={24} />
                          : <SvgIco name="map_detail" size={24} />
                        }
                        <p className="map-text">{ mapIsOpen ? 'List' : I18n.t("lb_showroom.showroom.list.map") }</p>
                      </div>
                    }

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </StickyFilterBar>

      { (filtersModalIsOpen || mobileFiltersOn) &&

        <div className="lv-filter__modal" onScroll={handleMobileScroll} id="mobile-filters-body">
          <div className="lv-filter__modal-container">
            <div className="lv-filter__modal-header">
              <SvgIco
                name={isMobileMenu ? 'arrow-back' : 'close'}
                onClick={isMobileMenu ? () => toggleMobileFilters(false) : () => closeFiltersModal}
                size={isMobileMenu ? 24 : 32}
              />
              <div className="lv-filter__modal-headline">{ I18n.t('properties.filter.tab_toggle_button') }</div>
              { showClearFiltersButton &&
                <div className="lv-filter__clear-filters-link" onClick={handleClearFilters}>
                  { I18n.t('apps.lb_showroom.filters.clear_filters') }
                </div>
              }
            </div>

            <div className={filtersWrapperClasses} id="filtersWrapper" ref={filterWrapper}>

              <Suspense fallback={
                          <div className="spinner-wrapper">
                            <div className="smoothie-spinner" />
                          </div>
                        }>

                <div className="lv-filter lv-filter__type">
                  <DropdownSelector
                    dynamicData={locationKinds}
                    isMobileMenu={isMobileMenu}
                    placeholder={I18n.t('apps.lb_showroom.showroom.list.property_kind')}
                    selectedCounterPhrase={I18n.t('apps.lb_showroom.showroom.list.property_kind')}
                    selectedData={selectedTypes}
                    selectAction={selectType}
                  />
                </div>

                <SuitableForFilter
                  clearSuitableFor={clearSuitableFor}
                  isMobileMenu={isMobileMenu}
                  toggleClearSuitableForFilter={() => setClearSuitableFor(prevState => !prevState)}
                />

                <div className="lv-filter lv-filter__area mobile">
                  <AreaFilter
                    deselectAll={deselectAll}
                    dynamicData={duplicateParsedData}
                    isMobileMenu={isMobileMenu}
                    placeholder={I18n.t('apps.lb_showroom.filters.choose_areas')}
                    scrollToggle={filtersMobileScroll}
                    selectedData={selectedAreaIds}
                    selectAction={toggleAreaSelect}
                    selectAll={(e) => selectAll(e, duplicateParsedData)}
                  />
                </div>

                { frontendLeasePrices
                  ? <div className="lv-filter lv-filter__price">
                    <DropDownContainer
                      isMobileMenu={isMobileMenu}
                      name="price"
                      selectedTooltip={selectedPriceTooltip}
                      text={I18n.t('lb_showroom.showroom.list.price')}>
                      <RangeSelector
                        dimension={slidersDefaultCurrencyFormatted}
                        filterType='PRICE'
                        handleSelectorChange={filterLocationByPrice}
                        maxValue={maxPrice}
                        minValue={0}
                        showInputLabels={!isMobileMenu}
                        showMaxMoreIcon={true}
                        submitOnChangeComplete={true}
                        value={selectedPrice}
                      />
                    </DropDownContainer>
                  </div>
                  : null
                }

                { !hideSquareFilter &&
                  <div className="lv-filter lv-filter__meters">

                    <DropDownContainer
                      isMobileMenu={isMobileMenu}
                      name={areaFilterIcon}
                      selectedTooltip={selectedSizeTooltip}
                      text={I18n.t('lb_showroom.showroom.list.size')}>
                      <div className="lv-filter__meters-section lv-filter__meters-section--meters">
                        <p>{areaFilterTitle}</p>
                        <NoLinearRangeSlider
                          dimension={areaUnit}
                          filterType='SQUARE'
                          handleSelectorChange={filterLocationBySquare}
                          maxValue={maxArea}
                          minValue={0}
                          showMaxMoreIcon={true}
                          submitOnChangeComplete={true}
                          value={selectedMeters}
                        />
                      </div>

                      <div className="lv-filter__meters-section">
                        <p>{ I18n.t('activemodel.attributes.service_request.employee_count') }</p>
                        <div className="input-range__hint">{areaFilterText}</div>

                        <NoLinearRangeSlider
                          dimension={I18n.t('search_agent.configuration.employees.selector_currency')}
                          employeeCalculation={true}
                          filterType='SQUARE'
                          handleSelectorChange={filterLocationBySquare}
                          maxValue={maxArea}
                          minValue={0}
                          showMaxMoreIcon={true}
                          submitOnChangeComplete={true}
                          value={selectedMeters}
                        />
                      </div>
                    </DropDownContainer>
                  </div>
                }

                <div className="lv-filter__mobile-filters-bottom">

                  <EnergyRatingFilter isMobileMenu={isMobileMenu} />

                  <FacilitiesFilter
                    clearFacilities={clearFacilities}
                    isMobileMenu={isMobileMenu}
                    toggleClearFacilitiesFilter={() => setClearFacilities(prevState => !prevState)}
                  />


                  { mobileFiltersOn &&
                    <Fragment>
                      <div className="lv-filter__search-agent-link-wrapper" id="mobile-filters-scroll-target">
                        <a href={ newSearchAgentPath + setSearchAgentUrl() } className="lv-filter__search-agent-link">
                          <span>{ I18n.t('lb_showroom.showroom.list.search_agent_menu_link') }</span>
                          <SvgIco name="keyboard-arrow-right-rounded-ios" size={16} />
                        </a>
                      </div>

                      { showApplyFiltersButton &&
                        <div className="lv-filter__button-wrapper">
                          <div className="lv-filter__search-button" id="applyFiltersButtonMobile" onClick={handleMobilesFiltersSubmit}>
                            <SvgIco name="sync" size={20} />
                            <span>{ I18n.t('lb_showroom.showroom.list.apply_filters') }</span>
                          </div>
                        </div>
                      }
                    </Fragment>
                  }
                </div>

              </Suspense>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

MainFilter.propTypes = {
  requestedParams: PropTypes.oneOfType([PropTypes.object]),
};

MainFilter.defaultProps = {
  requestParams: {}
};

export default React.memo(MainFilter);
