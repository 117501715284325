export const queryParseHelper = (requestParams, path, backButton, defaultParams) => {
  Object.keys(path).map(elem => {
    switch(elem) {
      case 'kinds[]':
        requestParams.kinds = [].concat(path[elem]);
        break;
      case 'energy_rating[]':
        requestParams.energy_rating = [].concat(path[elem]);
        break;
      case 'page':
        requestParams.page = path[elem];
        break;
      case 'rent_min':
        requestParams.rent_min = path[elem];
        break;
      case 'rent_max':
        requestParams.rent_max = path[elem];
        break;
      case 'area_min':
        requestParams.area_min = path[elem];
        break;
      case 'area_max':
        requestParams.area_max = path[elem];
        break;
      case 'per_page':
        requestParams.per_page = path[elem];
        break;
      case 'sort_by':
        requestParams.sort_by = path[elem];
        break;
      case 'street_id':
        requestParams.street_id = path[elem];
        break;
    }
  });

  if (backButton) {
    if (!path['kinds[]']) {
      requestParams.kinds = defaultParams.kinds ? defaultParams.kinds : [];
    }

    if (!path['energy_rating[]']) {
      requestParams.energy_rating = defaultParams.energy_rating ? defaultParams.energy_rating : [];
    }

    if (!path.page) {
      requestParams.page = defaultParams.page
    }

    if (!path.rent_min) {
      requestParams.rent_min = defaultParams.rent_min
    }

    if (!path.rent_max) {
      requestParams.rent_max = defaultParams.rent_max
    }

    if (!path.area_min) {
      requestParams.area_min = defaultParams.area_min
    }

    if (!path.area_max) {
      requestParams.area_max = defaultParams.area_max
    }

    if (!path.per_page) {
      requestParams.per_page = '12'
    }

    if (!path.sort_by) {
      requestParams.sort_by = '';
    }
    if (!path.street_id) {
      if (defaultParams.street_id) {
        requestParams.street_id = defaultParams.street_id
      } else {
        delete requestParams.street_id
      }
    }
  }

  return requestParams;
};
