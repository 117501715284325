import React, { useContext, Fragment } from 'react';
import PropTypes from 'prop-types';

import { SvgIco } from '../../SharedComponents/Icons';
import { Context } from "../index";

import './index.sass';


const HeaderSection = () => {

  const {
    contactInfoPhone,
    contactInfoPhoneWithoutCode,
    country,
    headerData,
    keywords,
    requestParams,
    showSeo,
    siteKey,
    subSite,
    totalLocationsCount,
  } = useContext(Context);

  const { locationsCount, sections } = keywords;

  const {
    description,
    descriptionWithAssets,
    extendedDescription,
    headline,
    streetName,
    cityName
  } = headerData;


  const findPos = (obj) => {
    let curtop = 0;
    if (obj.offsetParent) {
      do {
        curtop += obj.offsetTop;
      } while (obj = obj.offsetParent);
      return curtop
    }
    return undefined;
  };


  const scrollToSeoText = () => {
    const seoTextElement = document.getElementById('seo-text-description');
    const topMenuHeight = document.getElementById('top-menu').offsetHeight;
    const targetTop = findPos(seoTextElement) - topMenuHeight - 100;
    scrollTo({ top: targetTop, left: document.body, behavior: "smooth" });
  };


  const additionalTextSalesSection = () => {
    if (subSite === 'sales') {
      switch (true) {
        case (sections[0] === 'foreclosure' && sections.length < 2):
          return 'til salg på tvangsauktion';
          break;
        case (sections[0] === 'own_use' && sections.length < 2):
          return '- brugerejendomme til salg';
          break;
        case (sections[0] === 'investment' && sections.length < 2):
          return 'til investering';
          break;
        default:
          return 'til salg'
      }
    } else {
      return null
    }
  };


  const count = (locationsCount) => {
    switch (true) {
      case locationsCount === 1:
        return "one";
      case locationsCount > 1 && locationsCount < 5:
        return "few";
      case locationsCount >= 5:
        return "many";
      default:
        return "other";
    }
  };


  const locationsKind = requestParams.kinds && requestParams.kinds.length === 1 ? '.' + requestParams.kinds[0] : '';
  const kind = I18n.t(`global.models.location.kinds${locationsKind}.normal`);
  // const currentTotalLocationsCount = totalLocationsCount || locationsCount;
  const currentTotalLocationsCount = totalLocationsCount || totalLocationsCount === 0 ? totalLocationsCount : locationsCount;
  const translatedKinds = kind[count(currentTotalLocationsCount)] ? kind[count(currentTotalLocationsCount)] : kind['other'];
  const phoneNumber = siteKey === 'global' ? contactInfoPhone : contactInfoPhoneWithoutCode;


  return (
    <div className="lv-header__wrapper">
      <div className="container max-width">
        <div className="lv-header__container">
          <div className="row">
            { headline ?
              <Fragment>
                <div className="col-xl-8 col-lg-8 col-md-8">
                  <h1 className={`lv-header__headline ${siteKey !== 'dk' ? 'lv-header__capitalize-title' : ''}`}>
                    {/*{ !(totalLocationsCount === 0) ? totalLocationsCount : locationsCount } */}
                    <span className="notranslate">{ currentTotalLocationsCount } </span><span>{ showSeo }</span><span> { translatedKinds }</span>
                    { additionalTextSalesSection() }
                    { showSeo ? ': ' : ' ' }
                    {((streetName && requestParams.street_id) ? streetName : (cityName ? cityName : (showSeo ? headline : I18n.t(`apps.lb_showroom.header.results`))))}
                  </h1>
                  { !streetName && !cityName &&
                    <div className="lv-header__description">
                      { showSeo ? description : I18n.t('apps.lb_showroom.header.subheader') }
                      { showSeo && (extendedDescription || (descriptionWithAssets && descriptionWithAssets.length > 0)) && (
                        <div className="lv-header__read-more" onClick={scrollToSeoText}>
                          { I18n.t('generic.read_more') }
                        </div>
                      )}
                    </div>
                  }
                </div>

                <div className="lv-header__banner offset-xl-1 col-xl-3 col-lg-4 col-md-4">
                  <div className="lv-header__banner-wrapper">
                    <div className="lv-header__banner-item">
                      <SvgIco name="check" size={16} />
                      <span className="lv-header__banner-advantage">
                        { I18n.t(`apps.entry_page.search_area.value_points.lease.first`) }
                      </span>
                    </div>

                    { !contactInfoPhone &&
                      <div className="lv-header__banner-item">
                        <SvgIco name="check" size={16} />
                        <span className="lv-header__banner-advantage">
                          { I18n.t(`apps.entry_page.search_area.value_points.lease.third`) }
                        </span>
                      </div>
                    }

                    <div className="lv-header__banner-item">
                      <SvgIco name="check" size={16} />
                      <span className="lv-header__banner-advantage">
                        { siteKey === 'dk'
                          ? <div dangerouslySetInnerHTML={{__html: I18n.t('apps.entry_page.search_area.value_points.lease.two_dk')}} />
                          : I18n.t(`apps.entry_page.search_area.value_points.lease.second`, {'country_name': country})
                        }
                      </span>
                    </div>

                    { contactInfoPhone &&
                      <div className="lv-header__banner-item lv-header__banner-item--phone">
                        <SvgIco name="phone-green" size={16} />
                        <span className="lv-header__banner-advantage">
                          { I18n.t(`generic.call_us`) }:&nbsp;<a href={`tel://${contactInfoPhone}`}>{ phoneNumber }</a>
                        </span>
                      </div>
                    }

                  </div>
                </div>
              </Fragment>
              : <p className="lv-header__empty-search">{ I18n.t('apps.lb_showroom.showroom.list.empty_search_result') }</p>
            }
          </div>
        </div>
      </div>
    </div>
  );
}


export default HeaderSection;

HeaderSection.propTypes = {
  showSeo: PropTypes.bool,
};

HeaderSection.defaultProps = {
  showSeo: true,
}
