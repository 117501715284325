import React, { Component } from 'react';
import PropTypes from 'prop-types';


class ResultsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 0,
      maxIndex: 0,
      emptyResults: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    if (this.props !== prevProps) {
      this.setState({ selectedIndex: 0 });
      this.getMaxItems(this.props);

      if (this.props.moveDirection === 'down') {
        this.setState({
          selectedIndex: this.state.selectedIndex > this.state.maxIndex
            ? (this.state.selectedIndex = 0)
            : this.state.selectedIndex + 1,
        });
      } else if (this.props.moveDirection === 'up') {
        this.setState({
          selectedIndex: this.state.selectedIndex < 0
            ? this.state.maxIndex
            : this.state.selectedIndex - 1,
        });
      }
    }
  }

  getMaxItems = data => {
    const maxIndex = data.results.length
      ? data.results.map(el => el.entries.length).reduce((e, n) => e + n) - 1
      : 0;
    this.setState({ maxIndex });
  };

  buildLabel = (title) => <div className='location-search-label'>{ title }</div>;

  buildItem = (itemObj, index) => {
    const isSelected = this.state.selectedIndex === index ? 'selected' : '';
    if (isSelected) {
      this.props.refElem(itemObj.path)
    }


    return (
      <a
        key={itemObj.path+index}
        href={itemObj.path}
        className={`location-search-item ${isSelected} `}
      >
        <span>{itemObj.line}</span>
        <span>{itemObj.type === 'location' ? itemObj.teaser : `(${itemObj.teaser})`}</span>
      </a>
    );
  };

  buildLocationBlock = (locationObj) => {
    return(
      <div key={locationObj.label}>
        {this.buildLabel(locationObj.label)}
        {locationObj.entries.map(item => this.buildItem(item, this.index++))}
      </div>
    )
  };

  renderResults = () => {
    this.index = 0;
    return this.props.results.map(item => this.buildLocationBlock(item));
  };

  anyResults = () => !!this.props.results.length;

  render() {
    return (
      <div className="search-result-container">
        { this.anyResults() ? this.renderResults() : null }
      </div>
    );
  }
}

ResultsContainer.propTypes = {
  results: PropTypes.array.isRequired,
};

export default React.memo(ResultsContainer);
