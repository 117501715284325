import React from 'react';
import PropTypes from 'prop-types';

import { SvgIco } from "../SharedComponents/Icons";

import './index.sass';


const Breadcrumb = ({ breadcrumbs, root, detailsPage }) => {

  const linksList = breadcrumbs.map( (breadcrumb, index) => (
    <div className="breadcrumbs__link-wrapper" key={ breadcrumb.label }>
      { index === breadcrumbs.length - 1
        ? <div className="breadcrumbs__link breadcrumbs__last-one-link breadcrumbs__link--disabled">{ breadcrumb.label }</div>
        : <div className="breadcrumbs__link-wrapper">
            <a className="breadcrumbs__link" href={ breadcrumb.path }>
              { breadcrumb.label }
            </a>
            <SvgIco name="keyboard-arrow-right-rounded" size={15}/>
          </div>
      }
    </div>
  ));

  return (
    <div className={ `breadcrumbs ${ root ? root : '' } ${detailsPage ? 'details-page' : ''}`}>
      <div className="container">
        <div className="breadcrumbs__wrapper">{ linksList }</div>
      </div>
    </div>
  );
};

Breadcrumb.defaultProps = {
  breadcrumbs: [],
};

Breadcrumb.propTypes = {
  breadcrumbs: PropTypes.array,
};


export default Breadcrumb;
