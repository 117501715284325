export const locationsCountByKinds =(kinds, sums) => {
  let count = 0;
  const filtered = {};

  for (let kind of Array.from(kinds)) {
    if (sums[kind]) {
      count += sums[kind]['direct'] || 0;
    }

    for (let direct_kind in sums) {
      const sums_obj = sums[direct_kind];
      filtered[direct_kind] = filtered[direct_kind] || [];

      for (let idx in sums_obj['secondary']) {
        const location_secondary_kinds = sums_obj['secondary'][idx];
        if (!kinds.includes(direct_kind) && (filtered[direct_kind].indexOf(idx) === -1) && (location_secondary_kinds.constructor === Array) && location_secondary_kinds.includes(kind)) {
          filtered[direct_kind].push(idx);
          count += 1;
        }
      }
    }
  }

  return count;
};