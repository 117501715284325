export const advertBannersData = {
  searchAgent: {
    advertisement: true,
    search_agent: true,
    image_text: I18n.t('list-view.advertisement.create-text'),
    image_text_bottom: I18n.t('list-view.advertisement.search_agent'),
    title: I18n.t('list-view.advertisement.search_agent-main-title'),
    description: I18n.t('list-view.advertisement.search_agent-description'),
    text_above_button: I18n.t('list-view.advertisement.search_agent_above_button'),
    text_on_button: I18n.t('search_agents.edit.create_search_agent'),
    id: 2147483646,
  },
  signUp: {
    advertisement: true,
    search_agent: false,
    image_text: I18n.t('list-view.advertisement.list_your_office'),
    image_text_bottom: I18n.t('list-view.advertisement.match_office'),
    title: I18n.t('list-view.advertisement.provider-main-title'),
    description: I18n.t('list-view.advertisement.provider-description'),
    text_above_button: I18n.t('list-view.advertisement.provider_above_button'),
    text_on_button: I18n.t('apps.entry_page.entry_pages.become_provider_text.become_provider_now'),
    id: 2147483647,
  },
}
