import React, { Component } from 'react';
import Sticky from 'react-stickynode';

class StickyFilterBar extends Component {

  state ={
    previousScrollY: null,
    showStickyBar: false,
  };

  componentDidMount() {
    this.setState({previousScrollY: window.pageYOffset})
    window.addEventListener('scroll', this.handleStickyBarRender);
  };

  componentDidUpdate(prevProps, prevState) {
    const { open: mapIsOpen } = this.props.mapOptions;

    mapIsOpen !== prevProps.mapOptions.open && !mapIsOpen && this.setState({ showStickyBar: false });
  };

  handleStickyBarRender = () => {
    if (!this.state.mobileFiltersOn)  {
      const { previousScrollY } = this.state;
      const currentScrollY = window.pageYOffset;


      // We need this additional check for Safari and Firefox
      // to prevent appearance of Sticky-bar at the end of the page caused by overscroll
      window.pageYOffset + window.innerHeight < document.body.clientHeight &&
      this.setState({
        showStickyBar: previousScrollY > currentScrollY,
        previousScrollY: currentScrollY,
      });
    }
  };


  render() {
    return (
      <Sticky
        top="#top-menu"
        bottomBoundary=".nothing"
        innerZ={998}
        enabled={ !this.props.mapOptions.open && this.state.showStickyBar && !this.props.mobileFiltersOn }
      >
        {this.props.children}
      </Sticky>
    );
  }
}

export default StickyFilterBar;
